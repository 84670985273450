import { EventClickArg, EventDropArg, EventSourceInput } from '@fullcalendar/core';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import scrollgridPlugin from '@fullcalendar/scrollgrid';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Appointment from '@spike/appointment-model';
import { BlockCalendar } from '@spike/block-calendar-model';
import { useMarketplace, useTimeZone } from 'hooks';
import moment, { Moment } from 'moment-timezone';
import { FunctionComponent, createRef, useEffect, useMemo, useState } from 'react';
import {
    convertToBlockCalendarEvent,
    convertToEvent,
    convertToSlotGroupEvents,
    convertToTempEvent,
    getWeekBusinessHours,
    renderEvent
} from '../FullCalendar/FullCalendarUtils';
import NowIndicatorFullCalendar from '../FullCalendar/NowIndicatorFullCalendar';
import { useFullCalendarStyles } from '../FullCalendar/StylesFullCalendar';
import { SlotGroup, TempNewBooking } from '../FullCalendar/model';
import DayHeaderFullCalendar from './DayHeaderFullCalendar';
import ActionNotSupportedDialog from 'components/CreateBooking/UI/ActionNotSupportedDialog';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import clsx from 'clsx';

interface WeekFullCalendarProps {
    from: Moment;
    appointments: Array<Appointment>;
    blocks: Array<BlockCalendar>;
    selectedStaffIds?: Array<number>;
    multipleBookingsIds: Array<number>;
    tempNewBooking?: TempNewBooking;
    shrink: boolean;
    isSlotsView: boolean;
    onClickAppointment?: (id: number) => void;
    onClickBlockCalendar?: (id: number) => void;
    onRescheduled?: (appointmentId: number, at: Moment, revert: () => void) => void;
    onRescheduledBlocked?: (blocked: BlockCalendar, revert: () => void) => void;
    onBook?: (at: Moment) => void;
    onScroll: () => void;
    onClickSlotGroup?: (slotGroup: SlotGroup, slotElement: HTMLElement) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            'width': '100%',
            '& .fc .fc-timegrid-slot-label': {
                border: 'none !important'
            },
            '& .fc-bg-event': {
                opacity: 1
            },
            '& .fc-scroller .fc-timegrid-slot-label': {
                [theme.breakpoints.down('sm')]: {
                    height: '21px !important'
                },
                [theme.breakpoints.only('md')]: {
                    height: '33px !important'
                },
                [theme.breakpoints.only('lg')]: {
                    height: '42px !important'
                },
                [theme.breakpoints.only('xl')]: {
                    height: '49px !important'
                }
            },
            '& .fc-license-message': {
                display: 'none !important'
            }
        },
        slotsViewContainer: {
            '& .fc-timegrid-event-harness-inset': {
                left: '0px !important',
                right: '0px !important'
            },
            '& .fc .fc-timegrid-slot': {
                height: '32px !important',

                [theme.breakpoints.up('md')]: {
                    height: '44px !important'
                }
            }
        },
        dayHeader: {
            backgroundColor: 'white',
            borderTop: 'none !important',
            borderLeft: 'none !important',
            borderRight: 'none !important',
            borderBottom: '1px solid #D8D8D8 !important',
            verticalAlign: 'middle !important',
            [theme.breakpoints.down('sm')]: {
                height: '32px'
            },
            [theme.breakpoints.only('md')]: {
                height: '48px'
            },
            [theme.breakpoints.only('lg')]: {
                height: '60px'
            },
            [theme.breakpoints.only('xl')]: {
                height: '90px'
            }
        },
        nowIndicator: {
            '&.fc-timegrid-now-indicator-arrow': {
                border: 'none',
                width: '100%'
            },
            '&.fc-timegrid-now-indicator-line': {
                'border': '1px solid black !important',
                'height': '0px',
                'backgroundColor': 'black',
                '&::before': {
                    content: '""',
                    borderRadius: '100%',
                    position: 'absolute',
                    height: '8px',
                    width: '8px',
                    background: 'black',
                    left: '-1px',
                    marginTop: '-4px',
                    boxShadow: 'inherit',
                    zIndex: 999
                }
            }
        }
    })
);

export const WeekFullCalendar: FunctionComponent<WeekFullCalendarProps> = props => {
    const classes = useStyles();
    const timeZone = useTimeZone();
    const marketplace = useMarketplace();
    const fullcalendarClasses = useFullCalendarStyles();

    const today = moment().tz(timeZone);

    const wrapperRef = createRef<HTMLDivElement>();
    const fullCalendarRef = createRef<FullCalendar>();

    const [showActionNotSupported, setShowActionNotSupported] = useState(false);

    const staffState = useSelector((state: RootState) => state.staff);

    useEffect(() => {
        fullCalendarRef.current?.getApi().gotoDate(props.from.toDate());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.from]);

    useEffect(() => {
        props.tempNewBooking &&
            fullCalendarRef.current?.getApi().scrollToTime({ hour: props.tempNewBooking?.at.get('hour') });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tempNewBooking]);

    // Handle fullcalendar scroll event to close edit slot modal
    useEffect(() => {
        if (wrapperRef.current) {
            const scrollEl = wrapperRef.current.querySelector('.fc-scroller-liquid-absolute');

            if (scrollEl) {
                scrollEl.addEventListener('scroll', props.onScroll);

                return () => {
                    scrollEl.removeEventListener('scroll', props.onScroll);
                };
            }
        }
    }, []);

    const scrollTime = today.format('HH:00:00');

    const businessHours = getWeekBusinessHours(props.from, marketplace.schedule);

    const events: EventSourceInput = useMemo(() => {
        if (props.isSlotsView) {
            const period = {
                from: props.from.clone(),
                to: props.from.clone().endOf('week')
            };

            const staffs =
                props.selectedStaffIds && props.selectedStaffIds.length > 0
                    ? staffState.staff.filter(staff => props.selectedStaffIds?.includes(Number(staff.id)))
                    : staffState.staff;

            return convertToSlotGroupEvents(staffs, period, timeZone);
        }

        const events = props.appointments.map(appointment =>
            convertToEvent(appointment, timeZone, 'weekly', props.multipleBookingsIds)
        );

        const blocks = props.blocks.map(block => ({
            ...convertToBlockCalendarEvent(block, timeZone),
            rendering: 'background'
        }));

        return props.tempNewBooking
            ? [...events, ...blocks, convertToTempEvent(props.tempNewBooking, timeZone)]
            : [...events, ...blocks];
    }, [
        props.from,
        props.appointments,
        props.blocks,
        props.tempNewBooking,
        timeZone,
        props.multipleBookingsIds,
        props.isSlotsView,
        staffState.staff
    ]);

    const eventClickHandler = (args: EventClickArg) => {
        args.event.extendedProps.type === 'slot-group' &&
            props.onClickSlotGroup?.(args.event.extendedProps.slotGroup, args.el);

        args.event.extendedProps.type === 'appointment' &&
            props.onClickAppointment?.(args.event.extendedProps.appointmentId);

        args.event.extendedProps.type === 'block' && props.onClickBlockCalendar?.(args.event.extendedProps.blockId);
    };

    const dropHandler = (args: EventDropArg, timeZone: string) => {
        if (args.event.extendedProps.type === 'block') {
            const blockedTime: BlockCalendar = props.blocks.find(
                block => block.id === args.event.extendedProps.blockId
            )!;
            blockedTime.start = moment(args.event.start).tz(timeZone);
            blockedTime.end = moment(args.event.end).tz(timeZone);
            props.onRescheduledBlocked && props.onRescheduledBlocked(blockedTime, args.revert);
        } else if (args.event.extendedProps.isMultipleBooking) {
            setShowActionNotSupported(true);
            args.revert();
        } else {
            props.onRescheduled &&
                props.onRescheduled(
                    args.event.extendedProps.appointmentId,
                    moment(args.event.start).tz(timeZone),
                    args.revert
                );
        }
    };

    const dateClickHandler = (args: DateClickArg, timeZone: string) => {
        const at = moment(args.date).tz(timeZone);
        const quarter = Math.trunc(at.minutes() / 15);
        at.minutes(quarter * 15);
        props.onBook && props.onBook(at);
    };

    const fullCalendarComponent = useMemo(
        () => (
            <FullCalendar
                ref={fullCalendarRef}
                plugins={[interactionPlugin, momentTimezonePlugin, timeGridPlugin, scrollgridPlugin]}
                initialView="timeGridWeek"
                timeZone={timeZone}
                editable={true}
                eventDurationEditable={false}
                slotDuration="0:15:00"
                slotLabelInterval="1:00:00"
                snapDuration="0:10:00"
                expandRows={true}
                height="100%"
                headerToolbar={false}
                allDaySlot={false}
                stickyHeaderDates={false}
                dayHeaderContent={dayHeaderProps => (
                    <DayHeaderFullCalendar
                        staffs={staffState.staff}
                        appointments={props.appointments}
                        selectedStaffIds={props.selectedStaffIds}
                        {...dayHeaderProps}
                    />
                )}
                dayHeaderClassNames={[classes.dayHeader]}
                dayCellClassNames={[fullcalendarClasses.dayCell]}
                slotLabelFormat={{
                    hour: 'numeric',
                    omitZeroMinute: false
                }}
                slotLabelClassNames={[fullcalendarClasses.slotLabel]}
                slotLaneClassNames={[fullcalendarClasses.slotLane]}
                nowIndicator={true}
                nowIndicatorClassNames={[classes.nowIndicator]}
                nowIndicatorContent={props => <NowIndicatorFullCalendar {...props} />}
                scrollTime={scrollTime}
                events={events}
                eventContent={event => renderEvent(event, props.appointments)}
                eventClassNames={[props.isSlotsView ? fullcalendarClasses.slotEvent : fullcalendarClasses.event]}
                businessHours={businessHours}
                eventClick={eventClickHandler}
                eventDrop={args => dropHandler(args, timeZone)}
                dateClick={args => dateClickHandler(args, timeZone)}
                eventOrder={(a: any, b: any) => {
                    if (a.extendedProps.type === 'block' && b.extendedProps.type !== 'block') {
                        return -1;
                    } else if (a.extendedProps.type !== 'block' && b.extendedProps.type === 'block') {
                        return 1;
                    }
                    return 0;
                }}
                dayMinWidth={props.isSlotsView ? 150 : 1}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [events, props.shrink, staffState.staff, props.from, props.selectedStaffIds]
    );

    return (
        <>
            <div
                ref={wrapperRef}
                className={clsx(classes.container, props.isSlotsView && classes.slotsViewContainer)}
            >
                {fullCalendarComponent}
            </div>
            {showActionNotSupported && <ActionNotSupportedDialog onClose={() => setShowActionNotSupported(false)} />}
        </>
    );
};

export default WeekFullCalendar;
