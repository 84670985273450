import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useFullCalendarStyles = makeStyles((theme: Theme) =>
    createStyles({
        slotLabel: {
            'backgroundColor': 'white',
            'fontFamily': 'Poppins',
            'fontWeight': 400,
            'color': '#222222',
            'border': 'none !important',
            '&[data-time="00:00:00"] .fc-timegrid-slot-label-frame': {
                marginTop: '-40px'
            },
            [theme.breakpoints.down('sm')]: {
                'fontSize': '8px',
                '&:not([data-time="00:00:00"]) .fc-timegrid-slot-label-frame': {
                    marginTop: '-16px'
                }
            },
            [theme.breakpoints.only('md')]: {
                'fontSize': '9px',
                '&:not([data-time="00:00:00"]) .fc-timegrid-slot-label-frame': {
                    marginTop: '-22px'
                }
            },
            [theme.breakpoints.only('lg')]: {
                'fontSize': '11px',
                '&:not([data-time="00:00:00"]) .fc-timegrid-slot-label-frame': {
                    marginTop: '-27px'
                }
            },
            [theme.breakpoints.only('xl')]: {
                'fontSize': '14px',
                '&:not([data-time="00:00:00"]) .fc-timegrid-slot-label-frame': {
                    marginTop: '-34px'
                }
            }
        },
        slotLane: {
            'cursor': 'pointer',
            '&[data-time="00:00:00"], &[data-time="01:00:00"], &[data-time="02:00:00"], &[data-time="03:00:00"], &[data-time="04:00:00"], &[data-time="05:00:00"], &[data-time="06:00:00"], &[data-time="07:00:00"], &[data-time="08:00:00"], &[data-time="09:00:00"], &[data-time="10:00:00"], &[data-time="11:00:00"], &[data-time="12:00:00"], &[data-time="13:00:00"], &[data-time="14:00:00"], &[data-time="15:00:00"], &[data-time="16:00:00"], &[data-time="17:00:00"], &[data-time="18:00:00"], &[data-time="19:00:00"], &[data-time="20:00:00"], &[data-time="21:00:00"], &[data-time="22:00:00"], &[data-time="23:00:00"]':
                {
                    borderTop: '2px solid #BDBDBD !important'
                },
            '&[data-time="00:15:00"], &[data-time="00:30:00"], &[data-time="00:45:00"], &[data-time="01:15:00"], &[data-time="01:30:00"], &[data-time="01:45:00"], &[data-time="02:15:00"], &[data-time="02:30:00"], &[data-time="02:45:00"], &[data-time="03:15:00"], &[data-time="03:30:00"], &[data-time="03:45:00"], &[data-time="04:15:00"], &[data-time="04:30:00"], &[data-time="04:45:00"], &[data-time="05:15:00"], &[data-time="05:30:00"], &[data-time="05:45:00"], &[data-time="06:15:00"], &[data-time="06:30:00"], &[data-time="06:45:00"], &[data-time="07:15:00"], &[data-time="07:30:00"], &[data-time="07:45:00"], &[data-time="08:15:00"], &[data-time="08:30:00"], &[data-time="08:45:00"], &[data-time="09:15:00"], &[data-time="09:30:00"], &[data-time="09:45:00"], &[data-time="10:15:00"], &[data-time="10:30:00"], &[data-time="10:45:00"], &[data-time="11:15:00"], &[data-time="11:30:00"], &[data-time="11:45:00"], &[data-time="12:15:00"], &[data-time="12:30:00"], &[data-time="12:45:00"], &[data-time="13:15:00"], &[data-time="13:30:00"], &[data-time="13:45:00"], &[data-time="14:15:00"], &[data-time="14:30:00"], &[data-time="14:45:00"], &[data-time="15:15:00"], &[data-time="15:30:00"], &[data-time="15:45:00"], &[data-time="16:15:00"], &[data-time="16:30:00"], &[data-time="16:45:00"], &[data-time="17:15:00"], &[data-time="17:30:00"], &[data-time="17:45:00"], &[data-time="18:15:00"], &[data-time="18:30:00"], &[data-time="18:45:00"], &[data-time="19:15:00"], &[data-time="19:30:00"], &[data-time="19:45:00"], &[data-time="20:15:00"], &[data-time="20:30:00"], &[data-time="20:45:00"], &[data-time="21:15:00"], &[data-time="21:30:00"], &[data-time="21:45:00"], &[data-time="22:15:00"], &[data-time="22:30:00"], &[data-time="22:45:00"], &[data-time="23:15:00"], &[data-time="23:30:00"], &[data-time="23:45:00"]':
                {
                    borderTopColor: '#E9E9E9 !important'
                },
            [theme.breakpoints.down('sm')]: {
                height: '21px !important'
            },
            [theme.breakpoints.only('md')]: {
                height: '33px !important'
            },
            [theme.breakpoints.only('lg')]: {
                height: '42px !important'
            },
            [theme.breakpoints.only('xl')]: {
                height: '49px !important'
            }
        },
        dayCell: {
            backgroundColor: 'white !important',
            border: '1px solid #D8D8D8 !important',
            cursor: 'pointer'
        },
        event: {
            'backgroundColor': 'white',
            'border': 'none',
            'margin': '2px !important',
            [theme.breakpoints.down('sm')]: {
                borderRadius: '3px'
            },
            [theme.breakpoints.only('md')]: {
                borderRadius: '5px'
            },
            [theme.breakpoints.only('lg')]: {
                borderRadius: '7px'
            },
            [theme.breakpoints.only('xl')]: {
                borderRadius: '10px'
            },
            '& .fc-event-main': {
                padding: '0px !important'
            }
        },
        slotEvent: {
            border: 'none !important',
            boxShadow: 'none !important',
            backgroundColor: 'transparent'
        }
    })
);
