import { Box, Container, CssBaseline } from '@material-ui/core';
import { createStyles, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { fetchMasterDataThunk, MasterDataStatus } from '@spike/masterdata-action';
import useNonInitialEffect from '@versiondos/hooks';
import { cleanAll } from 'actions/ActionUtils';
import { fetchMarketplaceThunk, MarketplaceStatus } from '@spike/marketplace-action';
import {
    fetchOnboardingStepsThunk,
    onboardingCloseModal,
    onboardingOpenModal
} from 'actions/onboardingSteps/OnboardingStepsActions';
import NewFeatures from 'components/NewFeatures';
import OnboardingVideoModal from 'components/OnboardingSteps/UI/OnboardingVideoModal';
import Sidebar from 'components/Sidebar';
import { Spinner } from 'components/UI';
import { useAuth, useHasPremiumPlan } from 'hooks';
import Marketplace from '@spike/marketplace-model';
import Notifications from 'notifications';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { OnboardingStepsStatus } from 'reducers/onboardingSettings/onboardingStepsState';
import { isPublic as isPublicPath, requiresCleanUp } from 'routes/Paths';
import Routes from 'routes/Routes';
import { RootState } from 'store';
import { cuddlesTheme } from 'Theme';
import { useApiClientWrapper } from 'hooks';
import { useHubspotTrackingCode } from 'hooks';
import TwoWaySmsWebSockets from 'WebSockets/TwoWaySmsWebSockets';
import MarketplaceWebSockets from 'WebSockets/MarketplaceWebSocket';
import { OnboardingSteps as OnboardingStepsModel } from 'model/OnboardingSteps';
import { fetchSubscriptionsThunk, togglePremiumPlanPopup } from 'actions/subscriptions/SubscriptionsActions';
import BulkUploadWebSockets from './WebSockets/BulkUploadWebSocket';
import UserNotificationsWebSockets from 'WebSockets/UserNotificationsWebsocket';
import { SubscriptionsStatus } from 'reducers/subscriptions/SubscriptionsState';
import { debugConsoleLog } from 'utils/GeneralUtils';
import StaffWebSockets from 'WebSockets/StaffWebSocket';
import Clarity from 'utils/ClarityUtil';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            display: 'flex',
            width: '100%',
            height: '100vh',
            overflow: 'hidden',
            position: 'relative'
        },
        page: {
            display: 'flex',
            width: '100%'
        },
        spinner: {
            marginTop: '100px'
        }
    })
);

const App: FunctionComponent = () => {
    const classes = useStyles();
    //Get HubSpot hook
    const { setPathPageView, setIdentity } = useHubspotTrackingCode();

    const dispatch = useDispatch();
    const masterDataStatus = useSelector<RootState, MasterDataStatus>(state => state.masterData.status);
    const marketplaceStatus = useSelector<RootState, MarketplaceStatus>(state => state.marketplace.status);
    const auth = useAuth();
    const marketplace = useSelector<RootState, Marketplace>(state => state.marketplace.marketplace);
    const subscriptionsStatus = useSelector<RootState, SubscriptionsStatus>(state => state.subscriptions.status);
    const onboardingStatus = useSelector<RootState, OnboardingStepsStatus>(state => state.onboardingSteps.status);

    const hasPremiumPlan = useHasPremiumPlan();
    const apiClientWrapper = useApiClientWrapper();

    const location = useLocation();

    const isPublic = isPublicPath(location);

    /*HubSpot tracking */
    setPathPageView(location.pathname);
    if (auth.user) {
        setIdentity(auth.user.email);
    }
    /*End HubSpot tracking */

    const isLogin = location.pathname.indexOf('login') > -1;
    const isSignUp = location.pathname.indexOf('signup') > -1;
    const isAgreements = location.pathname.indexOf('agreements/create') > -1;
    const isAgreementsSignature = location.pathname.indexOf('agreements/signature') > -1;
    const isSMSCreate = location.pathname.indexOf('marketing/sms/create') > -1;
    const isResetPassword = location.pathname.indexOf('resetpassword') > -1;
    const isForgotPassword = location.pathname.indexOf('forgotpassword') > -1;
    const isPayByLink = location.pathname.indexOf('pay-by-link') > -1;
    const isUpdateVaccines = location.pathname.indexOf('update-vaccines') > -1;
    const withoutContainer =
        isLogin ||
        isSignUp ||
        isForgotPassword ||
        isResetPassword ||
        isAgreements ||
        isAgreementsSignature ||
        isSMSCreate ||
        isPayByLink ||
        isUpdateVaccines;
    const isImpersonateOrActivate =
        location.pathname.indexOf('impersonate') > -1 || location.pathname.indexOf('activate_business') > -1;

    const [isMarketplaceLoaded, setIsMarketplaceLoaded] = useState(false);
    const [isOnboardingLoaded, setIsOnboardingLoaded] = useState(false);

    const onBoardingProgress = useSelector<RootState, OnboardingStepsModel>(
        state => state.onboardingSteps.onboardingSteps
    );

    const verifyOnBoardingProgress = () => {
        const isOnBoardingCompleted =
            onBoardingProgress.businessHoursDone &&
            onBoardingProgress.teamDone &&
            onBoardingProgress.petTypesDone &&
            onBoardingProgress.servicesDone &&
            onBoardingProgress.clientsDone &&
            onBoardingProgress.bookingDone;
        return isOnBoardingCompleted;
    };

    useEffect(() => {
        debugConsoleLog({
            auth,
            isImpersonateOrActivate,
            masterDataStatus,
            apiClientWrapper
        });
        !isImpersonateOrActivate &&
            masterDataStatus !== MasterDataStatus.Set &&
            dispatch(fetchMasterDataThunk(apiClientWrapper));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useNonInitialEffect(() => {
        if (requiresCleanUp(location)) {
            cleanAll(dispatch);
            setIsMarketplaceLoaded(false);
        }
    }, [location]);

    useNonInitialEffect(() => {
        if (
            masterDataStatus === MasterDataStatus.FetchSuccess &&
            auth.signedIn &&
            (marketplaceStatus === MarketplaceStatus.Initial || marketplaceStatus !== MarketplaceStatus.Set)
        ) {
            dispatch(fetchMarketplaceThunk(apiClientWrapper));
        }
    }, [masterDataStatus, auth.signedIn, dispatch]);

    useNonInitialEffect(() => {
        if (!isMarketplaceLoaded) {
            setIsMarketplaceLoaded(
                !auth.signedIn || [MarketplaceStatus.FetchSuccess, MarketplaceStatus.Set].includes(marketplaceStatus)
            );
        }
    }, [marketplaceStatus]);

    useNonInitialEffect(() => {
        if (marketplace.id !== undefined) {
            dispatch(fetchSubscriptionsThunk());
            dispatch(fetchOnboardingStepsThunk());
            if (auth.user && auth.user.email) {
                Clarity.set('userEmail', auth.user.email); //add clarity custom-tag
            }
        }
    }, [marketplace.id, dispatch]);

    useNonInitialEffect(() => {
        switch (onboardingStatus) {
            case OnboardingStepsStatus.FetchSuccess:
                setIsOnboardingLoaded(true);
                if (verifyOnBoardingProgress() === false) {
                    dispatch(onboardingOpenModal());
                } else {
                    dispatch(onboardingCloseModal());
                }
                break;
        }
    }, [onboardingStatus]);

    useNonInitialEffect(() => {
        switch (subscriptionsStatus) {
            case SubscriptionsStatus.FetchSuccess: {
                const premiumPopupStorage = localStorage.getItem('premiumPlanPopupClosed');

                if (!hasPremiumPlan && premiumPopupStorage === null) {
                    dispatch(togglePremiumPlanPopup(true));
                }
            }
        }
    }, [subscriptionsStatus]);

    if (!isPublic && !auth.signedIn) {
        window.location.href = '/login';
    }

    return (
        <MuiThemeProvider theme={cuddlesTheme}>
            <CssBaseline />

            <TwoWaySmsWebSockets />
            <BulkUploadWebSockets />
            <MarketplaceWebSockets />
            <StaffWebSockets />
            <UserNotificationsWebSockets />

            {isPublic || (isMarketplaceLoaded && isOnboardingLoaded) ? (
                withoutContainer ? (
                    <Fragment>
                        <Routes />
                        <Notifications />
                    </Fragment>
                ) : (
                    <Container
                        maxWidth={false}
                        disableGutters={true}
                        className={classes.container}
                    >
                        <Sidebar />
                        <Box className={classes.page}>
                            <Routes />
                            <Notifications />
                            <NewFeatures />
                            <OnboardingVideoModal />
                        </Box>
                    </Container>
                )
            ) : (
                <Spinner className={classes.spinner} />
            )}
        </MuiThemeProvider>
    );
};

export default App;
