import React from 'react';
import { EventContentArg } from '@fullcalendar/core';
import { getStaffSlotsPetsCountByDate } from 'components/StaffSchedule/utils/StaffScheduleUtils';
import { createStyles, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaw } from '@fortawesome/pro-light-svg-icons';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            gap: 4,
            right: 5,
            padding: 3,
            fontSize: 11,
            color: '#fff',
            float: 'right',
            fontWeight: 500,
            borderRadius: 4,
            display: 'inline-flex',
            alignItems: 'center',
            backgroundColor: '#92B4A7',
            position: 'absolute'
        },
        placeholder: {
            height: 1,
            visibility: 'hidden'
        }
    })
);

const ScheduleTypeTotalBadge: React.FC<EventContentArg> = props => {
    const classes = useStyles();

    const root = React.useRef<HTMLDivElement>(null);

    const [topPosition, setTopPosition] = React.useState(0);

    const totalSlots = React.useMemo(() => {
        return getStaffSlotsPetsCountByDate(props.event.extendedProps.slots, props.event.extendedProps.from);
    }, [props.event.extendedProps.from, props.event.extendedProps.slots]);

    React.useEffect(() => {
        const checkIntersection = () => {
            if (!root.current) {
                return;
            }

            const rootRect = root.current.getBoundingClientRect();
            const lanes = document.querySelectorAll('.fc-timeline-lane');

            let calculatedTopPosition = 0;
            let foundIntersection = false;

            lanes.forEach(lane => {
                const laneRect = lane.getBoundingClientRect();

                // Check if the lane intersects with the root element
                const isIntersecting =
                    rootRect.left < laneRect.right &&
                    rootRect.right > laneRect.left &&
                    rootRect.top < laneRect.bottom &&
                    rootRect.bottom > laneRect.top;

                if (isIntersecting) {
                    calculatedTopPosition = laneRect.height - rootRect.height - 7;
                    setTopPosition(calculatedTopPosition);
                    foundIntersection = true;
                }
            });

            // Retry if no intersection was found
            if (!foundIntersection || calculatedTopPosition < 10) {
                setTimeout(checkIntersection, 100);
            }
        };

        checkIntersection();
    }, [root.current]);

    return (
        <>
            <div className={classes.placeholder}></div>
            <div
                ref={root}
                className={classes.root}
                style={{ top: `${topPosition}px` }}
            >
                <FontAwesomeIcon icon={faPaw} />
                Total: {totalSlots}
            </div>
        </>
    );
};

export default ScheduleTypeTotalBadge;
