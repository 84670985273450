import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError } from '@spike/model';
import clsx from 'clsx';
import moment from 'moment';
import { PetVaccine } from '@spike/medical-history-model';
import { FunctionComponent, useState } from 'react';
import { reduceResolution, wbp } from 'Theme';
import Pet from '@spike/pet-model';
import VaccineRow from 'components/Pet/MedicalHistory/Edit/Vaccines/VaccineRow';
import PetCard from 'components/UI/PetCard';
import Button from 'components/UI/V2/Button/Button';

interface VaccinesProps {
    pet: Pet;
    disabled: boolean;
    vaccines: Array<PetVaccine>;
    errors?: Array<FieldError>;
    onChange?: (vaccines: Array<PetVaccine>) => void;
    className?: string;
    onClickSave?: (pet: Pet, newVaccines: Array<PetVaccine>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap'
        },
        vaccinesContainer: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '10px'
            },
            [theme.breakpoints.down('md')]: {
                marginTop: '0px'
            }
        }
    })
);

export const Vaccines: FunctionComponent<VaccinesProps> = props => {
    const classes = useStyles();

    const [errors, setErrors] = useState<Array<FieldError>>([]);

    const [newVaccines, setNewVaccines] = useState<Array<PetVaccine>>([]);

    const changeHandler = (updatedVaccine: PetVaccine) => {
        props.onChange &&
            props.onChange(
                props.vaccines.map(vaccine =>
                    vaccine.uuid === updatedVaccine.uuid ? { ...updatedVaccine } : { ...vaccine }
                )
            );

        const updatedVaccines = newVaccines.map(vaccine =>
            vaccine.uuid === updatedVaccine.uuid ? updatedVaccine : vaccine
        );

        if (!updatedVaccines.some(vaccine => vaccine.uuid === updatedVaccine.uuid)) {
            updatedVaccines.push(updatedVaccine);
        }

        setNewVaccines(updatedVaccines);
    };

    const saveHandler = () => {
        props.onClickSave && props.onClickSave(props.pet, newVaccines);
    };

    const vaccines = (
        <Box className={classes.vaccinesContainer}>
            {props.vaccines.map(vaccine => (
                <VaccineRow
                    key={vaccine.uuid}
                    vaccine={vaccine}
                    onChange={changeHandler}
                    errors={props.errors}
                    updateVaccines={true}
                />
            ))}
        </Box>
    );

    const petCardView = (
        <Box className={classes.vaccinesContainer}>
            <PetCard pet={props.pet} />
        </Box>
    );

    const button = (
        <Box className={classes.vaccinesContainer}>
            <Button
                size="large"
                label="Save"
                variant="green"
                isLoading={props.disabled}
                disabled={props.disabled || newVaccines.length == 0}
                onClick={saveHandler}
            />
        </Box>
    );
    return (
        <Box className={clsx(classes.container, props.className)}>
            {petCardView} {vaccines} {button}
        </Box>
    );
};

export default Vaccines;
