import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Button } from 'components/UI';
import { FunctionComponent } from 'react';

interface HeaderProps {
    onAdd?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '16px 32px',
            backgroundColor: '#FAFAFA',
            [theme.breakpoints.down('md')]: {
                padding: '16px'
            },
            [theme.breakpoints.down('sm')]: {
                backgroundColor: '#fff'
            }
        },
        addBtn: {
            'height': 55,
            'padding': '0px 20px',
            '& .MuiButton-startIcon': {
                '& svg': {
                    width: 16,
                    height: 16,
                    [theme.breakpoints.down('lg')]: {
                        width: 14,
                        height: 14
                    }
                }
            },
            '& span': {
                paddingLeft: 4,
                fontSize: 18,
                fontWeight: 600
            },
            [theme.breakpoints.down('lg')]: {
                'height': 45,
                'padding': '0px 20px',
                '& span': {
                    fontSize: 14,
                    fontWeight: 600,
                    paddingLeft: 0
                }
            }
        }
    })
);

const Header: FunctionComponent<HeaderProps> = ({ onAdd }) => {
    const classes = useStyles();

    return (
        <Box className={classes.headerWrapper}>
            {onAdd && (
                <Button
                    label="Add New Card"
                    size="large"
                    color="orange"
                    startIcon={faPlus}
                    className={classes.addBtn}
                    onClick={onAdd}
                />
            )}
        </Box>
    );
};

export default Header;
