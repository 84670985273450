// src/pages/UpdateVaccines/UpdateVaccines.tsx
import UpdateVaccinesComponent from 'components/UpdateExpiredVaccines';
import { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

interface MatchParams {
    uuid?: string;
}

type UpdateVaccinesProps = RouteComponentProps<MatchParams>;

const UpdateExpiredVaccines: FunctionComponent<UpdateVaccinesProps> = props => {
    return <UpdateVaccinesComponent uuid={props.match.params.uuid || ''} />;
};

export default UpdateExpiredVaccines;
