import Client from '@spike/client-model';
import { ClientExpiredVaccinesDto } from 'actions/updateExpiredVaccines/UpdateExpiredVaccinesDto';

export interface UpdateExpiredVaccinesState {
    clientExpiredVaccines: ClientExpiredVaccinesDto | null;
    status: UpdateExpiredVaccinesStatus;
    loading: boolean;
    error: boolean;
}

export enum UpdateExpiredVaccinesStatus {
    Initial,
    Getting,
    GettingSuccess,
    SaveSuccess,
    Error
}
