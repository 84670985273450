import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin, faPhone } from '@fortawesome/pro-light-svg-icons';
import useStyles from './styles/UpdateVaccinesStyles';
import CuddlesLogo from './UI/CuddlesLogo';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import store, { RootState } from 'store';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import NotFound from 'components/UI/NotFound';
import PetVaccinesCard from './UI/PetVaccinesCard';
import {
    UpdateExpiredVaccinesState,
    UpdateExpiredVaccinesStatus
} from 'reducers/updateExpiredVaccines/UpdateExpiredVaccinesState';
import { getClient, newPetVaccines } from 'actions/updateExpiredVaccines/updateExpiredVaccinesActions';
import { ClientExpiredVaccinesDto } from 'actions/updateExpiredVaccines/UpdateExpiredVaccinesDto';
import { Spinner } from 'components/UI';
import Pet from '@spike/pet-model';
import { PetVaccineDto, convertToPet } from '@spike/pets-action';
import { convertMarketplace, MarketplaceDto } from '@spike/marketplace-action';
import Vaccines from './UI/Vaccines';
import { PetVaccine } from '@spike/medical-history-model';

interface UpdateExpiredVaccinesProps {
    uuid: string;
}

const base_url = `${process.env.REACT_APP_HOST_URL}`;

export const UpdateExpiredVaccines: FunctionComponent<UpdateExpiredVaccinesProps> = ({ uuid }) => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const loading = useSelector<RootState, boolean>(state => state.updateVaccines.loading);

    const fetchError = useSelector<RootState, boolean>(state => state.updateVaccines.error);

    const status = useSelector<RootState, UpdateExpiredVaccinesStatus>(state => state.updateVaccines.status);

    const updateExpiredVaccines = useSelector<RootState, UpdateExpiredVaccinesState>(state => state.updateVaccines);

    const [clientExpiredVaccines, setClientExpiredVaccines] = useState<ClientExpiredVaccinesDto | null>(null);
    const [pet, setPet] = useState<Pet | null>(null);

    const masterData = store.getState().masterData.data;

    const [showFormUpdateVaccines, setShowFormUpdateVaccines] = useState(false);
    const [editMedicalHistoryVaccines, setEditMedicalHistoryVaccines] = useState<Array<PetVaccine>>([]);

    const handlerUpdatePet = (petSelected: Pet) => {
        setPet(petSelected);
        const listVaccines = [...petSelected.medicalHistory.vaccines].sort((a, b) => {
            return moment(a.expiration).isAfter(moment(b.expiration))
                ? 1
                : moment(b.expiration).isAfter(moment(a.expiration))
                ? -1
                : 0;
        });
        setEditMedicalHistoryVaccines(listVaccines);
        setShowFormUpdateVaccines(true);
    };

    const changeVaccinesHandler = (vaccines: Array<PetVaccine>) => {
        setEditMedicalHistoryVaccines(vaccines);
    };

    const onBack = () => {
        setPet(null);
        setShowFormUpdateVaccines(false);
    };

    useEffect(() => {
        dispatch(getClient(uuid));
    }, [uuid]);

    useEffect(() => {
        if (status === UpdateExpiredVaccinesStatus.GettingSuccess) {
            setClientExpiredVaccines(updateExpiredVaccines.clientExpiredVaccines);
        }

        if (status === UpdateExpiredVaccinesStatus.SaveSuccess) {
            onBack();
            dispatch(getClient(uuid));
        }
    }, [status]);

    const businessName = clientExpiredVaccines
        ? clientExpiredVaccines?.marketplace?.business_name || '' // Retorna '' si no está definido
        : '';

    const businessAddres =
        clientExpiredVaccines &&
        clientExpiredVaccines?.marketplace?.addresses &&
        clientExpiredVaccines?.marketplace?.addresses[0].address_line_one +
            (clientExpiredVaccines?.marketplace?.addresses[0].address_line_two
                ? ', ' + clientExpiredVaccines?.marketplace?.addresses[0].address_line_two
                : '') +
            ', ' +
            clientExpiredVaccines?.marketplace?.addresses[0].city +
            ', ' +
            clientExpiredVaccines?.marketplace?.addresses[0].state +
            ', ' +
            clientExpiredVaccines?.marketplace?.addresses[0].zipcode;

    const expiredVaccinesCounter = (vaccines: PetVaccineDto[]) => {
        return vaccines.reduce((count, vaccine) => {
            const expirationDate = moment(vaccine.expiration_date);
            return expirationDate.isBefore(moment()) ? count + 1 : count;
        }, 0);
    };

    if (loading) {
        return <Spinner className={classes.spinnerStyle} />;
    }

    const savePetVaccines = (pet: Pet, newVaccines: Array<PetVaccine>) => {
        if (pet && uuid && newVaccines.length > 0) {
            dispatch(
                newPetVaccines(
                    pet,
                    newVaccines,
                    clientExpiredVaccines?.token || '',
                    clientExpiredVaccines?.marketplace.id
                )
            );
        }
    };

    return (
        <Box className={classes.root}>
            {!loading && (
                <Box>
                    <Box className={classes.titleContainer}>
                        {showFormUpdateVaccines && pet && (
                            <Box className={classes.header}>
                                {
                                    <FontAwesomeIcon
                                        icon={faArrowLeft}
                                        className={classes.icon}
                                        onClick={onBack}
                                    />
                                }
                                <Typography className={classes.titleBack}>Back</Typography>
                            </Box>
                        )}
                        <Typography className={classes.title}>Update Expired Vaccines</Typography>
                    </Box>
                </Box>
            )}
            <Box className={classes.wrapper}>
                {fetchError && (
                    <NotFound
                        customContainerClass={classes.notFoundContainer}
                        title="Oops! Client Not Found"
                        subtitle="Oops! We couldn't find the client you're looking for."
                    />
                )}
                {!loading && !fetchError && (
                    <Box className={classes.body}>
                        <Box className={classes.storeInfo}>
                            <Box className={classes.logo}>
                                {clientExpiredVaccines?.marketplace?.image_url ? (
                                    <img
                                        src={clientExpiredVaccines?.marketplace?.image_url}
                                        alt={businessName}
                                    />
                                ) : (
                                    <Typography className={classes.storeName}>{businessName}</Typography>
                                )}
                            </Box>
                            <Box className={classes.storeDetails}>
                                <Typography className={classes.storeName}>{businessName}</Typography>
                                <Typography className={classes.storeContact}>
                                    <FontAwesomeIcon
                                        className={classes.storeInfoIcon}
                                        icon={faPhone}
                                        style={{ marginRight: 8 }}
                                    />
                                    {clientExpiredVaccines?.marketplace?.phone || 'N/A'}
                                </Typography>
                                <Typography className={classes.storeAddress}>
                                    <FontAwesomeIcon
                                        className={classes.storeInfoIcon}
                                        icon={faMapPin}
                                        style={{ marginRight: 8 }}
                                    />
                                    {businessAddres || 'No Address'}
                                </Typography>
                            </Box>
                        </Box>
                        {!showFormUpdateVaccines && (
                            <>
                                <Box className={classes.boxDefault}>
                                    <Box className={classes.petVaccinesDetails}>
                                        <Box className={classes.petVaccinesDetailsInfo}>
                                            <Typography
                                                variant="h1"
                                                className={classes.petVaccinesTitle}
                                            >
                                                Your Pets
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                {clientExpiredVaccines?.customer?.pets?.map(item => {
                                    const marketplaceDto = clientExpiredVaccines.marketplace;
                                    marketplaceDto.pet_requirement_list = [];
                                    masterData.staff.training = [];
                                    masterData.grooming.holdingAreas = [];
                                    masterData.grooming.security = [];
                                    masterData.grooming.visibility = [];
                                    masterData.grooming.sizeSeparation = [];
                                    masterData.vaccines = [];

                                    const marketplace = convertMarketplace(marketplaceDto, masterData);
                                    const pet = convertToPet(
                                        masterData,
                                        marketplace,
                                        clientExpiredVaccines?.time_zone,
                                        item
                                    );
                                    const countVaccinesExpired = expiredVaccinesCounter(item.pet_vaccine_expirations);

                                    return (
                                        <Box
                                            className={classes.boxDefault}
                                            key={pet.id}
                                        >
                                            <PetVaccinesCard
                                                pet={pet}
                                                countVaccinesExpired={countVaccinesExpired}
                                                onUpdatePetVaccines={handlerUpdatePet}
                                            />
                                        </Box>
                                    );
                                })}
                            </>
                        )}

                        {showFormUpdateVaccines && pet && (
                            <Vaccines
                                pet={pet}
                                disabled={loading || false}
                                vaccines={editMedicalHistoryVaccines}
                                onChange={changeVaccinesHandler}
                                onClickSave={savePetVaccines}
                            />
                        )}

                        {/*  */}
                    </Box>
                )}
            </Box>

            {!fetchError && (
                <Box className={classes.footer}>
                    <Typography className={classes.footerText}>Powered by</Typography>
                    <CuddlesLogo className={classes.cuddlesLogo} />
                </Box>
            )}
        </Box>
    );
};

export default UpdateExpiredVaccines;
