import { makeStyles, createStyles, Typography, Theme } from '@material-ui/core';
import { FunctionComponent, ReactNode } from 'react';
import Info from '../Info';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorText: {
            'fontSize': 16,
            'fontWeight': 500,
            '& strong': {
                fontWeight: 600
            }
        }
    })
);

interface DeclinedCardAlertProps {
    content: ReactNode;
}

const DeclinedCardAlert: FunctionComponent<DeclinedCardAlertProps> = ({ content }) => {
    const classes = useStyles();

    return (
        <Info
            textComponent={<Typography className={classes.errorText}>{content}</Typography>}
            icon="alert"
            color="#EF4F57"
            backgroundColor="#FBB9BC33"
            iconColor="#CC444B"
        />
    );
};

export default DeclinedCardAlert;
