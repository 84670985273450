// src/actions/updateExpiredVaccines/UpdateExpiredVaccines.ts

import { ThunkAction } from 'redux-thunk';
import axios, { AxiosResponse } from 'axios';
import { showError, showSuccess } from '@spike/notifications-action';
import {
    CLIENT_FETCH_START,
    CLIENT_FETCH_SUCCESS,
    CLIENT_FETCH_ERROR,
    UPDATE_VACCINES_SAVE_START,
    UPDATE_VACCINES_SAVE_SUCCESS,
    UPDATE_VACCINES_SAVE_ERROR,
    UpdateVaccinesAction
} from './UpdateExpiredVaccinesActionTypes';
import ApiClient from 'api';
import { RootState } from 'store';
import { ClientExpiredVaccinesDto } from './UpdateExpiredVaccinesDto';
import Pet from '@spike/pet-model';
import { convertToSavePetDto } from './PetDataConverter';
import { serialize } from 'object-to-formdata';
import { PetVaccine } from '@spike/medical-history-model';
import { createMultipartTokenConfig } from 'api/ApiClient';

const HTTP_STATUS_401_UNAUTHORIZED = 401;
const base_url = `${process.env.REACT_APP_HOST_URL}`;
const getCustomerLinkUrl = base_url + '/api/public/v1/pet_expired_vaccines/';
const savePetVaccineLinkUrl = base_url + '/api/public/v1/pets/';

export const getClient = (uuid?: string | number): ThunkAction<void, RootState, unknown, any> => {
    return async dispatch => {
        dispatch(getClientStart());

        try {
            const response: AxiosResponse<ClientExpiredVaccinesDto> = await ApiClient.post(getCustomerLinkUrl, {
                uuid: uuid
            });
            dispatch(getClientSuccess(response.data));
        } catch (apiError) {
            dispatch(error('Error get client.'));
            dispatch(showError('Error get client.'));
        }
    };
};

const getClientStart = (): UpdateVaccinesAction => ({
    type: CLIENT_FETCH_START
});

const getClientSuccess = (clientExpiredVaccinesDto: ClientExpiredVaccinesDto): UpdateVaccinesAction => {
    return {
        type: CLIENT_FETCH_SUCCESS,
        payload: { loading: false, clientExpiredVaccines: clientExpiredVaccinesDto }
    };
};

export const error = (error: string): UpdateVaccinesAction => ({
    type: CLIENT_FETCH_ERROR,
    payload: { loading: false, fetchError: error }
});

export const newPetVaccines = (
    pet: Pet,
    newVaccines: Array<PetVaccine>,
    token: string,
    marketplaceId?: number
): ThunkAction<void, RootState, unknown, any> => {
    return async dispatch => {
        dispatch(saveStart());

        try {
            const saveUrl = `${savePetVaccineLinkUrl}${pet.id}`;

            const petRequest = convertToSavePetDto(pet, newVaccines, marketplaceId!);

            let formData = new FormData();

            const options = {
                indices: false,
                nullsAsUndefineds: false,
                booleansAsIntegers: false,
                allowEmptyArrays: false
            };

            formData = serialize(petRequest, options, formData, 'pet');

            await ApiClient.patch(saveUrl, formData, createMultipartTokenConfig(token));
            dispatch(saveSuccess());
            dispatch(showSuccess('Pet updated! 🎉'));
        } catch (apiError: unknown) {
            if (axios.isAxiosError(apiError) && apiError.response) {
                if (apiError.response.status === HTTP_STATUS_401_UNAUTHORIZED) {
                    //  dispatch(failed());
                }

                if (apiError.response.status === 422) {
                    const errorResponse = apiError.response.data;
                    const errorMessages = Object.keys(errorResponse).map(key => `${key}: ${errorResponse[key]}`);
                    errorMessages.forEach(message => {
                        dispatch(showError(message));
                    });
                } else {
                    dispatch(showError('Error update vaccines.'));
                }

                dispatch(error('Error save vaccines.'));
                dispatch(showError('Error save vaccines.'));
                console.error(apiError);
            }
        }
    };
};

const saveStart = (): UpdateVaccinesAction => ({
    type: UPDATE_VACCINES_SAVE_START
});

const saveSuccess = (): UpdateVaccinesAction => {
    return {
        type: UPDATE_VACCINES_SAVE_SUCCESS,
        payload: { loading: false }
    };
};

export const errorSave = (error: string): UpdateVaccinesAction => ({
    type: UPDATE_VACCINES_SAVE_ERROR,
    payload: { loading: false, saveError: error }
});
