import React from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClickAwayListener, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { SlotGroup } from 'components/Calendar3/FullCalendar/model';
import { CounterField } from 'components/UI/V2/CounterField/CounterField';
import Button from 'components/UI/V2/Button/Button';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { isCustomSlot } from 'components/StaffSchedule/utils/StaffScheduleUtils';
import { v4 as uuid } from 'uuid';
import { saveStaffSlotsThunk } from 'actions/staff/StaffActions';
import { StaffSlots } from 'model/Staff';
import { showError } from '@spike/notifications-action';

interface EditGroupSlotFullCalendarProps {
    slotGroup: SlotGroup;
    slotElement: HTMLElement;
    onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            inset: 0,
            zIndex: 9,
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',

            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        root: {
            gap: 24,
            zIndex: 10,
            padding: '15px 24px 15px 16px',
            textAlign: 'center',
            position: 'fixed',
            borderRadius: '25px 25px 0px 0px',
            backgroundColor: '#fff',

            [theme.breakpoints.down('sm')]: {
                inset: 'auto 0px 0px 0px !important'
            },
            [theme.breakpoints.up('md')]: {
                gap: 8,
                width: 255,
                bottom: 'auto',
                borderRadius: 12,
                position: 'absolute',
                padding: '38px 16px 15px 16px',
                border: 'solid 1px #D4D4D4',
                boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.20)'
            }
        },
        chip: {
            width: 56,
            height: 6,
            marginBottom: 24,
            borderRadius: 100,
            display: 'inline-block',
            backgroundColor: '#D4D4D4',

            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        title: {
            fontSize: 22,
            fontWeight: 500,

            [theme.breakpoints.up('md')]: {
                fontSize: 15
            }
        },
        closeButton: {
            top: 9,
            right: 10,
            padding: 0,
            zIndex: 10,
            fontSize: 18,
            border: 'none',
            display: 'none',
            cursor: 'pointer',
            position: 'absolute',
            backgroundColor: 'transparent',

            [theme.breakpoints.up('md')]: {
                display: 'block'
            }
        },
        slotGroupContainer: {
            gap: 12,
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 16
        },
        slotGroupHeader: {
            [theme.breakpoints.down('sm')]: {
                display: 'none !important'
            }
        },
        slotGroup: {
            display: 'grid',
            alignItems: 'center',
            justifyContent: 'space-between',
            gridTemplateColumns: '70% 30%'
        },
        staffInfo: {
            'gap': 8,
            'display': 'flex',
            'alignItems': 'center',

            '& p': {
                fontSize: 14
            }
        },
        staffAvatar: {
            width: 26,
            height: 26,
            borderRadius: 100,
            objectFit: 'cover'
        },
        counterField: {
            gap: 4,
            justifyContent: 'flex-end'
        },
        saveButton: {
            padding: 0,
            width: '100%',
            marginTop: 4,

            [theme.breakpoints.down('sm')]: {
                height: 47
            }
        }
    })
);

export const EditGroupSlotFullCalendar: React.FC<EditGroupSlotFullCalendarProps> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const staffState = useSelector((state: RootState) => state.staff);

    const [isLoading, setIsLoading] = React.useState(false);
    const [slotGroup, setSlotGroup] = React.useState(props.slotGroup);

    const isSlotGroupEdited = React.useMemo(() => {
        return props.slotGroup.staffSlots.some(group => {
            return (
                group.slot.petsCount !==
                slotGroup.staffSlots.find(slot => slot.slot.uuid === group.slot.uuid)?.slot.petsCount
            );
        });
    }, [props.slotGroup.staffSlots, slotGroup.staffSlots]);

    const desktopPosition = React.useMemo(() => {
        if (!props.slotElement) {
            return { x: 0, y: 0 };
        }

        const sidebarElement = document.querySelector('[data-testid="sidebar"]');

        const rect = props.slotElement.getBoundingClientRect();

        const yPosition = rect.top + props.slotElement.offsetHeight;
        let xPosition = rect.left + props.slotElement.offsetWidth - 265;

        const maxXPosition = window.outerWidth - (sidebarElement?.scrollWidth || 0) - 265;

        if (xPosition >= maxXPosition) {
            xPosition = maxXPosition;
        }

        return {
            x: xPosition,
            y: yPosition
        };
    }, [props.slotElement]);

    React.useEffect(() => {
        setSlotGroup(props.slotGroup);
    }, [props.slotGroup]);

    const changeSlotHandler = (value: number, uuid: string) => {
        const updatedSlotGroup = {
            ...slotGroup,
            staffSlots: slotGroup.staffSlots.map(group =>
                group.slot.uuid === uuid ? { ...group, slot: { ...group.slot, petsCount: value } } : group
            )
        };

        setSlotGroup(updatedSlotGroup);
    };

    const saveSlotGroupHandler = async () => {
        setIsLoading(true);

        const updatedStaffSlots = slotGroup.staffSlots.filter(groupSlot => {
            const originalSlot = props.slotGroup.staffSlots.find(slot => slot.slot.uuid === groupSlot.slot.uuid);
            return originalSlot?.slot.petsCount !== groupSlot.slot.petsCount;
        });

        const savePromises = updatedStaffSlots.map(group => {
            const staff = staffState.staff.find(s => s.id === group.staff.id);
            if (!staff) return Promise.resolve();

            const isCustomDaySlot = isCustomSlot(group.slot);

            const staffSlots: StaffSlots = {
                ...staff.slots,

                // If the slot is a custom day slot, we need to add it to the custom days array
                ...(isCustomDaySlot && {
                    customDays: [
                        ...staff.slots.customDays.filter(slot => slot.uuid !== group.slot.uuid),
                        {
                            on: true,
                            uuid: group.slot.uuid,
                            date: slotGroup.date,
                            time: group.slot.time,
                            petsCount: group.slot.petsCount
                        }
                    ]
                }),

                // If the slot is a default slot, we need to create a new custom day slot for given date/time
                ...(!isCustomDaySlot && {
                    customDays: [
                        ...staff.slots.customDays,
                        {
                            on: true,
                            uuid: uuid(),
                            date: slotGroup.date,
                            time: group.slot.time,
                            petsCount: group.slot.petsCount
                        }
                    ]
                })
            };

            return new Promise(resolve => {
                dispatch(
                    saveStaffSlotsThunk(
                        {
                            ...staff,
                            slots: staffSlots
                        },
                        () => resolve(true)
                    )
                );
            });
        });

        try {
            await Promise.all(savePromises);
            props.onClose();
        } catch (error) {
            dispatch(showError('Failed to save some staff slots'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className={classes.backdrop}></div>

            <ClickAwayListener onClickAway={props.onClose}>
                <div
                    className={classes.root}
                    style={{
                        left: desktopPosition.x,
                        top: desktopPosition.y
                    }}
                >
                    <button
                        className={classes.closeButton}
                        onClick={props.onClose}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>

                    <div className={classes.chip}></div>

                    <div className={classes.slotGroupContainer}>
                        <div className={clsx(classes.slotGroup, classes.slotGroupHeader)}>
                            <Typography className={classes.title}>&nbsp;</Typography>
                            <Typography className={classes.title}>Pets</Typography>
                        </div>

                        {slotGroup.staffSlots.map(group => (
                            <div
                                key={group.slot.uuid}
                                className={classes.slotGroup}
                            >
                                <div className={classes.staffInfo}>
                                    <img
                                        src={group.staff.person.avatar}
                                        alt={group.staff.person.firstName}
                                        className={classes.staffAvatar}
                                    />
                                    <Typography className={classes.title}>{group.staff.person.firstName}</Typography>
                                </div>

                                <CounterField
                                    minLimit={0}
                                    disabled={isLoading}
                                    value={group.slot.petsCount}
                                    className={classes.counterField}
                                    onChange={value => changeSlotHandler(value, group.slot.uuid)}
                                />
                            </div>
                        ))}
                    </div>

                    <Button
                        label="Save"
                        variant="green"
                        isLoading={isLoading}
                        disabled={!isSlotGroupEdited}
                        className={classes.saveButton}
                        onClick={saveSlotGroupHandler}
                    />
                </div>
            </ClickAwayListener>
        </>
    );
};
