import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Grid, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Button, PriceFormat, Spinner, TextField2 } from 'components/UI';
import AdyenPayment, { CompletedInfo } from 'components/Payments/Adyen/AdyenPayment';
import { useDispatch, useSelector } from 'react-redux';
import { useTimeZone } from 'hooks';
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';
import {
    fetchSubscriptionsThunk,
    quoteSubscriptionThunk,
    saveSubscriptionsThunk
} from 'actions/subscriptions/SubscriptionsActions';
import store, { RootState } from 'store';
import useNonInitialEffect from '@versiondos/hooks';
import { SubscriptionsStatus } from 'reducers/subscriptions/SubscriptionsState';
import { Subscription, SubscriptionQuote } from 'model/Subscriptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { showError } from '@spike/notifications-action';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons';

interface Props {
    loading: boolean;
    onUpgrade: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'padding': '0px 16px',

            [theme.breakpoints.up('md')]: {
                padding: 0
            },
            [theme.breakpoints.down('sm')]: {
                flex: 1
            },

            '& b': {
                fontWeight: 600
            }
        },
        title: {
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,
            marginBottom: 25
        },
        card: {
            width: '100%',

            [theme.breakpoints.up('md')]: {
                borderRadius: 16,
                padding: '0px 22px',
                border: 'solid 1px #D4D4D4'
            }
        },
        cardHeader: {
            paddingTop: 10,
            paddingBottom: 15,
            marginBottom: 24,
            alignItems: 'center',
            borderBottom: 'solid 1px #D4D4D4',

            [theme.breakpoints.up('md')]: {
                paddingTop: 24
            }
        },
        promoCode: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative'
        },
        promoCodeField: {
            '& .MuiInputBase-root': {
                height: 54
            },
            '& fieldset': {
                border: 'solid 1px #222222 !important'
            },
            '& input': {
                fontSize: 16,
                paddingRight: 110
            }
        },
        promoCodeSubmit: {
            'right': 8,
            'height': 39,
            'padding': '0px 22px',
            'position': 'absolute',

            '& span': {
                fontSize: 16
            }
        },
        validCode: {
            'marginLeft': 5,
            'fontWeight': 600,
            'color': '#5E8677',
            'display': 'inline-flex',
            'alignItems': 'center',

            '& button': {
                border: 0,
                fontSize: 16,
                color: '#7A7A7A',
                appearance: 'none',
                background: 'none',
                cursor: 'pointer'
            }
        },
        invalidCode: {
            fontSize: 16,
            fontWeight: 500,
            marginTop: 10,
            color: '#EF4F57'
        },
        summary: {
            'color': '#000',
            'paddingTop': 22,
            'paddingBottom': 15,

            '& p': {
                fontSize: 16,
                lineHeight: 1.4
            }
        },
        summaryTotal: {
            '& p': {
                fontSize: 16,
                fontWeight: 600
            }
        },
        cardFooter: {
            'fontSize': 16,
            'lineHeight': 1.5,
            'color': '#7A7A7A',
            'borderTop': 'solid 1px #D4D4D4',

            '& ul': {
                [theme.breakpoints.down('xs')]: {
                    paddingLeft: 20
                }
            },
            '& li': {
                marginBottom: '1em'
            }
        },
        actions: {
            'display': 'flex',
            'alignItems': 'flex-end',
            'textAlign': 'center',

            '& button': {
                'height': 47,
                'padding': '0px 17px',
                'borderRadius': 30,

                '& label': {
                    fontSize: 16,
                    fontWeight: 600
                }
            }
        },
        continueBtn: {
            width: '100%'
        },

        errorMessage: {
            'textAlign': 'center',

            '& p': {
                fontSize: 16,
                lineHeight: 1.4,
                marginTop: 15
            },
            '& button': {
                marginTop: 20
            }
        }
    })
);

export const UpgradePlanForm: React.FunctionComponent<Props> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const timeZone = useTimeZone();

    const staffId = store.getState().login.auth.user?.staffId;

    const created = useSelector<RootState, Subscription | undefined>(state => state.subscriptions.created);
    const quote = useSelector<RootState, SubscriptionQuote | undefined>(state => state.subscriptions.quote);
    const subscriptionStatus = useSelector<RootState, SubscriptionsStatus>(state => state.subscriptions.status);

    const emptySubscription = {
        endAt: null,
        uuid: uuid(),
        active: false,
        channel: 'cuddles',
        staffId: Number(staffId),
        startAt: moment().tz(timeZone)
    };

    const inputRef = useRef<HTMLInputElement>(null);

    const billingDay = moment().tz(timeZone).format('Do');

    const [loading, setLoading] = useState<boolean>(false);
    const [promoCode, setPromoCode] = useState<string>('');
    const [showPayment, setShowPayment] = useState<boolean>(false);
    const [invalidCode, setInvalidCode] = useState<boolean>(false);
    const [paymentError, setPaymentError] = useState<boolean | string>(false);

    const quoteSubscription = (code: string) => {
        dispatch(quoteSubscriptionThunk(code));
    };

    const continueToPaymentHandler = async () => {
        if (promoCode.length > 0 && !hasDiscount) {
            quoteSubscription(promoCode);

            return;
        }

        setLoading(true);

        await dispatch(
            saveSubscriptionsThunk({
                ...emptySubscription,
                ...(promoCode.length > 0 && { promoCode })
            })
        );
    };

    const applyPromoCodeHandler = () => {
        quoteSubscription(promoCode);
    };

    const removeDiscountHandler = () => {
        setPromoCode('');
        quoteSubscription('');
        setInvalidCode(false);

        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    const errorPaymentHandler = () => {
        dispatch(showError('Error with payment'));
    };

    const completedPaymentHandler = (completedInfo: CompletedInfo) => {
        if (!completedInfo.success) {
            setPaymentError(completedInfo.message);

            return;
        }

        props.onUpgrade();
    };

    const hasDiscount: boolean = useMemo(() => {
        return (quote && quote?.discount > 0) || false;
    }, [quote]);

    useEffect(() => {
        quoteSubscription(promoCode);
        dispatch(fetchSubscriptionsThunk());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (promoCode.length === 0) {
            removeDiscountHandler();
        }

        if (promoCode.length > 0 && hasDiscount) {
            removeDiscountHandler();
        }
        // eslint-disable-next-line
    }, [promoCode]);

    useNonInitialEffect(() => {
        if (subscriptionStatus === SubscriptionsStatus.SaveSuccess) {
            setLoading(false);
            setShowPayment(true);
        } else if (subscriptionStatus === SubscriptionsStatus.FetchQuoteSuccess) {
            setInvalidCode(promoCode.length > 0 && !hasDiscount);
        }
    }, [subscriptionStatus]);

    return (
        <Grid
            container
            spacing={4}
            className={classes.root}
        >
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {!showPayment ? (
                        <>
                            <Grid
                                item
                                xs={12}
                            >
                                <Typography className={classes.title}>Order Summary</Typography>

                                <Box className={classes.card}>
                                    <Grid
                                        container
                                        className={classes.cardHeader}
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                        >
                                            <Typography>
                                                New Plan: <b>Premium</b>
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            style={{ textAlign: 'right' }}
                                        >
                                            <Typography>
                                                <PriceFormat price={`${quote?.price}`} />
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Box className={classes.promoCode}>
                                        <TextField2
                                            ref={inputRef}
                                            maxLength={100}
                                            value={promoCode}
                                            placeholder="Promo Code"
                                            className={classes.promoCodeField}
                                            onChange={value => setPromoCode(value)}
                                        />
                                        <Button
                                            size="medium"
                                            color="black"
                                            label="Apply"
                                            loading={props.loading}
                                            className={classes.promoCodeSubmit}
                                            onClick={applyPromoCodeHandler}
                                        />
                                    </Box>

                                    {invalidCode && (
                                        <Typography className={classes.invalidCode}>Invalid Promo code</Typography>
                                    )}

                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.summary}
                                    >
                                        {hasDiscount && (
                                            <>
                                                <Grid
                                                    item
                                                    xs={6}
                                                >
                                                    <Typography>Subtotal</Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    style={{
                                                        textAlign: 'right'
                                                    }}
                                                >
                                                    <Typography>
                                                        <b>
                                                            <PriceFormat price={`${quote?.price}`} />
                                                        </b>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                >
                                                    <Grid
                                                        item
                                                        xs
                                                    >
                                                        <Typography>
                                                            Discount
                                                            <span className={classes.validCode}>
                                                                {promoCode}
                                                                <button
                                                                    type="button"
                                                                    aria-label="Remove discount"
                                                                    onClick={removeDiscountHandler}
                                                                >
                                                                    <FontAwesomeIcon icon={faTimesCircle} />
                                                                </button>
                                                            </span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        style={{
                                                            textAlign: 'right'
                                                        }}
                                                    >
                                                        <Typography>
                                                            <b>
                                                                -
                                                                <PriceFormat price={`${quote?.discount}`} />
                                                            </b>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}

                                        <Grid
                                            item
                                            xs={6}
                                            className={classes.summaryTotal}
                                        >
                                            <Typography>Total Due Today</Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            className={classes.summaryTotal}
                                            style={{ textAlign: 'right' }}
                                        >
                                            <Typography>
                                                <PriceFormat price={`${quote?.total}`} />
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Box className={classes.cardFooter}>
                                        <ul>
                                            <li>
                                                Today we&apos;ll charge you <PriceFormat price={`${quote?.total}`} />,
                                                for the cost of your new plan.
                                            </li>
                                            <li>
                                                We will bill you the full price every month on the {billingDay}, after
                                                the promotion ends, unless you cancel.
                                            </li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.actions}
                            >
                                <Button
                                    size="large"
                                    color="black"
                                    label="Continue"
                                    className={classes.continueBtn}
                                    onClick={continueToPaymentHandler}
                                />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid
                                item
                                xs={12}
                            >
                                <Typography className={classes.title}>Payment Info</Typography>
                                {created?.id && (
                                    <>
                                        {paymentError ? (
                                            <Box className={classes.errorMessage}>
                                                <FontAwesomeIcon
                                                    size="5x"
                                                    color="#EF4F57"
                                                    icon={faCircleXmark}
                                                />
                                                <Typography>{paymentError}</Typography>
                                                <Button
                                                    label="Back"
                                                    color="black"
                                                    onClick={() => {
                                                        setPaymentError(false);
                                                    }}
                                                />
                                            </Box>
                                        ) : (
                                            <AdyenPayment
                                                subscriptionId={created?.id}
                                                hideRemovePaymentMethodButton={true}
                                                hideStoredPaymentMethods={true}
                                                onError={errorPaymentHandler}
                                                onComplete={completedPaymentHandler}
                                            />
                                        )}
                                    </>
                                )}
                            </Grid>
                        </>
                    )}
                </>
            )}
        </Grid>
    );
};
