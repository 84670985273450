import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles, createStyles, Box, Typography, Tabs, Tab, useMediaQuery, Theme } from '@material-ui/core';
import useNonInitialEffect from '@versiondos/hooks';
import { setNotificationBellAmount } from 'actions/notificationBell/NotificationBellActions';
import { updateThunk } from 'actions/userNotifications/userNotificationActions';
import clsx from 'clsx';
import { UserNotification } from 'model/UserNotifications';
import { FunctionComponent, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserNotificationsStatus } from 'reducers/userNotifications/UserNotificationsState';
import { RootState } from 'store';
import NotificationsDrawer from './UI/NotificationsDrawer';
import Badge from '@material-ui/core/Badge';
import { debugConsoleLog } from 'utils/GeneralUtils';
import { NotificationsList } from './UI/NotificationsList';
import { NotificationsEmpty } from './UI/NotificationsEmpty';
import NotMobileYet from 'components/UI/NotMobileYet';
import PetCard from 'components/PetCard';

interface Props {
    open: boolean;
    onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            width: '100%',
            position: 'relative'
        },
        closeButton: {
            top: 4,
            right: 6,
            border: 0,
            padding: 8,
            fontSize: 20,
            cursor: 'pointer',
            position: 'absolute',
            background: 'transparent',
            color: '#000',
            [theme.breakpoints.down('sm')]: {
                top: 20,
                right: 8
            }
        },

        title: {
            fontSize: 22,
            fontWeight: 600,
            paddingLeft: 24,
            marginTop: 24,
            marginBottom: 32
        },

        menu: {
            'width': '100%',
            'minHeight': 'unset',
            'padding': '0px 24px',
            'borderBottom': 'solid 1px #D4D4D4',

            '& .MuiTabs-flexContainer': {
                gap: 16
            },
            '& .MuiTab-wrapper': {
                gap: 6,
                flexDirection: 'row'
            },
            '& .MuiTabs-indicator': {
                backgroundColor: '#000'
            }
        },
        menuTab: {
            'padding': 0,
            'paddingBottom': 8,
            'minWidth': 'unset',
            'minHeight': 'unset',
            'textAlign': 'left',
            'textTransform': 'none',
            'fontWeight': 400,

            '& .MuiTab-wrapper': {
                alignItems: 'start',
                borderBottom: '0',
                color: '#707070',
                [theme.breakpoints.down('md')]: {
                    fontSize: '16px'
                }
            }
        },
        menuTabSelected: {
            'fontWeight': 600,
            'textAlign': 'left',
            'textTransform': 'none',

            '& .MuiTab-wrapper': {
                color: '#000000'
            },
            '& .MuiTab-root': {
                borderBottom: 'black 3px solid'
            }
        },
        customBadge: {
            'width': 24,
            'height': 24,
            'color': '#000',
            'display': 'flex',
            'position': 'static',
            'transform': 'unset',
            'alignItems': 'center',
            'justifyContent': 'center',
            'backgroundColor': '#F2D2A2',
            'borderRadius': '50%',
            'fontWeight': 600,

            '&.MuiBadge-invisible': {
                display: 'none'
            }
        },

        scrollBody: {
            'flex': 1,
            'overflowX': 'hidden',
            'overflowY': 'scroll',

            '&::-webkit-scrollbar': {
                width: 16,
                backgroundColor: '#ffffff',
                [theme.breakpoints.down('sm')]: {
                    width: 0
                }
            },

            '&::-webkit-scrollbar-track': {
                backgroundColor: '#ffffff'
            },

            '&::-webkit-scrollbar-thumb': {
                borderRadius: 16,
                backgroundColor: '#babac0',
                border: '4px solid #fff'
            },

            '&::-webkit-scrollbar-button': {
                display: 'none'
            }
        },
        customContainerNotMobile: {
            position: 'relative',
            height: '100vh',
            width: '100vw',
            marginLeft: 'auto'
        },
        petCardContainer: {
            zindex: 1400
        }
    })
);

enum NotificationsTabsEnum {
    Unread,
    Read
}

export const Notifications: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const [openModal, setOpenModal] = useState(props.open);

    const [selectedTab, setSelectedTab] = useState<NotificationsTabsEnum>(NotificationsTabsEnum.Unread);

    const drawerScrollRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    const notificationStatus = useSelector<RootState, UserNotificationsStatus>(state => state.userNotifications.status);

    const readNotifications = useSelector<RootState, Array<UserNotification>>(state => state.userNotifications.read);

    const unreadNotifications = useSelector<RootState, Array<UserNotification>>(
        state => state.userNotifications.unread
    );

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.only('md'));
    const isNetbook = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const nShow = isMobile ? 4 : isTablet ? 5 : isNetbook ? 6 : 7;

    const [showNUnread, setShowNUnread] = useState(nShow);
    const [showNRead, setShowNRead] = useState(nShow);
    const [openPetCard, setOpenPetCard] = useState(false);
    const [selectedPetId, setSelectedPetId] = useState<number | null>(null);

    const [readSelected, unreadSelected] = useMemo(
        () => [selectedTab === NotificationsTabsEnum.Read, selectedTab === NotificationsTabsEnum.Unread],
        [selectedTab]
    );

    const handleCloseNotificationModal = () => {
        setOpenModal(false);
        props.onClose && props.onClose();
    };

    const handleOpenPetCard = (petId: number) => {
        setOpenPetCard(true);
        setSelectedPetId(petId);
        props.onClose && props.onClose();
    };

    const handlePetRefresh = () => {
        setSelectedPetId(null);
        setOpenPetCard(false);
    };

    useNonInitialEffect(() => {
        setOpenModal(props.open);
    }, [props.open]);

    useNonInitialEffect(() => {
        if (notificationStatus === UserNotificationsStatus.UpdateSuccess) {
            if (unreadNotifications.length - showNUnread >= 0) {
                dispatch(setNotificationBellAmount(unreadNotifications.length - showNUnread));
            } else {
                dispatch(setNotificationBellAmount(0));
            }
        }
    }, [notificationStatus]);

    useNonInitialEffect(() => {
        if (!openModal) {
            const limit = unreadNotifications.length < showNUnread ? unreadNotifications.length : showNUnread;

            for (let i = 0; i < limit; i++) {
                if (unreadNotifications[i].receiptId) {
                    dispatch(updateThunk(unreadNotifications[i].receiptId!, true, false));
                }
            }
        }
    }, [openModal]);

    const handleScroll = () => {
        if (drawerScrollRef.current) {
            const scrollContainer = drawerScrollRef.current;
            const scrollHeight = scrollContainer.scrollHeight;
            const height = scrollContainer.clientHeight;
            const maxScrollTop = scrollHeight - height;

            const isScrollAtBottom =
                scrollContainer.scrollTop === maxScrollTop || scrollContainer.scrollTop > maxScrollTop * 0.6;

            if (isScrollAtBottom) {
                // Show more readed notifications
                if (readSelected && showNRead < readNotifications.length) {
                    setShowNRead(numRead => numRead + 5);
                }

                // Show more unread notifications
                if (unreadSelected && showNUnread < unreadNotifications.length) {
                    setShowNUnread(numUnread => numUnread + 5);
                }
            }
        }
    };

    const readBadge = (
        <>
            Read
            <Badge
                classes={{ badge: classes.customBadge }}
                badgeContent={readNotifications.length}
            />
        </>
    );
    const unreadBadge = (
        <>
            Unread
            <Badge
                classes={{ badge: classes.customBadge }}
                badgeContent={unreadNotifications.length}
            />
        </>
    );

    return (
        <>
            <NotificationsDrawer
                open={openModal}
                onClose={() => handleCloseNotificationModal()}
            >
                <>
                    <Box className={classes.header}>
                        <button
                            type="button"
                            aria-label="Close notification modal"
                            className={classes.closeButton}
                            onClick={() => handleCloseNotificationModal()}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                        </button>

                        <Typography className={classes.title}>Notifications</Typography>
                        {/* 
						<NotMobileYet customContainerClass={classes.customContainerNotMobile} showButton={false} />
						*/}

                        <Tabs
                            disabled={false}
                            value={selectedTab}
                            className={classes.menu}
                            aria-label="Notifications menu"
                            onChange={(_, value) => setSelectedTab(value)}
                        >
                            <Tab
                                label={unreadSelected ? unreadBadge : 'Unread'}
                                className={clsx(classes.menuTab, {
                                    [classes.menuTabSelected]: unreadSelected
                                })}
                            />
                            <Tab
                                label={readSelected ? readBadge : 'Read'}
                                className={clsx(classes.menuTab, {
                                    [classes.menuTabSelected]: readSelected
                                })}
                            />
                        </Tabs>
                    </Box>

                    <div
                        ref={drawerScrollRef}
                        className={classes.scrollBody}
                        onScroll={handleScroll}
                    >
                        {unreadSelected && (
                            <NotificationsList
                                onRedirect={handleCloseNotificationModal}
                                onOpenPetCard={handleOpenPetCard}
                                notifications={unreadNotifications.filter((notification, index) => {
                                    return index < showNUnread;
                                })}
                                emptyMessage={
                                    <NotificationsEmpty
                                        title="You've read everything"
                                        subtitle="You have no unread notifications."
                                        image="/images/userNotifications/alldone.png"
                                    />
                                }
                            />
                        )}

                        {readSelected && (
                            <NotificationsList
                                onRedirect={handleCloseNotificationModal}
                                onOpenPetCard={handleOpenPetCard}
                                notifications={readNotifications.filter((notification, index) => {
                                    return index < showNRead;
                                })}
                                emptyMessage={
                                    <NotificationsEmpty
                                        title="All done"
                                        subtitle="You’ve caught up with all your notifications."
                                        image="/images/userNotifications/alldone.png"
                                    />
                                }
                            />
                        )}

                        {openPetCard && (
                            <Box className={classes.petCardContainer}>
                                <PetCard
                                    petId={selectedPetId!}
                                    optionMenuSelected="vaccines"
                                    onClose={handlePetRefresh}
                                />
                            </Box>
                        )}
                    </div>
                </>
            </NotificationsDrawer>
        </>
    );
};

export default Notifications;
