import { useMemo } from 'react';

const path = '/images/creditCards/';

const icons: Map<string, string> = new Map([
    ['visa', path.concat('icon-visa.svg')],
    ['mc', path.concat('icon-mastercard.svg')],
    ['amex', path.concat('icon-amex.svg')],
    ['diners', path.concat('icon-diners-club.svg')],
    ['cash', path.concat('icon-cash.svg')],
    ['default', path.concat('icon-credit-card.svg')]
]);

const brandAliases: Map<string, string> = new Map([
    ['MasterCard', 'mc'],
    ['MsaterCard', 'mc'],
    ['American Express', 'amex'],
    ['Diners Club', 'diners']
]);

const useCardIcon = (brand: string): string => {
    return useMemo(() => {
        if (!brand) {
            return icons.get('default')!;
        }
        const normalizedBrand = brandAliases.get(brand) || brand.toLowerCase();
        return icons.get(normalizedBrand) || icons.get('default')!;
    }, [brand]);
};

export default useCardIcon;
