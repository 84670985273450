import React from 'react';
import { StaffSlot, StaffSlots } from 'model/Staff';
import { ClickAwayListener, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { CounterField } from 'components/UI/V2/CounterField/CounterField';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { isCustomSlot } from 'components/StaffSchedule/utils/StaffScheduleUtils';
import { v4 as uuid } from 'uuid';
import Button from 'components/UI/V2/Button/Button';
import { saveStaffSlotsThunk } from 'actions/staff/StaffActions';
import { Moment } from 'moment-timezone';

export interface EditSingleSlotFullCalendarProps {
    date: Moment;
    slot: StaffSlot;
    staffId: number;
    slotElement: HTMLElement;
    onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            inset: 0,
            zIndex: 9,
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',

            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        root: {
            gap: 24,
            zIndex: 10,
            display: 'flex',
            padding: '15px 16px',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            position: 'fixed',
            borderRadius: '25px 25px 0px 0px',
            backgroundColor: '#fff',

            [theme.breakpoints.down('sm')]: {
                inset: 'auto 0px 0px 0px !important'
            },
            [theme.breakpoints.up('md')]: {
                gap: 8,
                width: 160,
                bottom: 'auto',
                borderRadius: 12,
                padding: '12px 10px',
                position: 'absolute',
                border: 'solid 1px #D4D4D4',
                boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.20)'
            }
        },
        chip: {
            width: 56,
            height: 6,
            borderRadius: 100,
            display: 'inline-block',
            backgroundColor: '#D4D4D4',

            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        title: {
            fontSize: 22,
            fontWeight: 500,

            [theme.breakpoints.up('md')]: {
                fontSize: 15
            }
        },
        saveButton: {
            padding: 0,
            width: '100%',
            marginTop: 4,

            [theme.breakpoints.down('sm')]: {
                height: 47
            }
        },
        closeButton: {
            top: 9,
            right: 10,
            padding: 0,
            zIndex: 10,
            fontSize: 18,
            border: 'none',
            display: 'none',
            cursor: 'pointer',
            position: 'absolute',
            backgroundColor: 'transparent',

            [theme.breakpoints.up('md')]: {
                display: 'block'
            }
        },
        counterField: {
            [theme.breakpoints.down('sm')]: {
                '& label': {
                    fontSize: 30
                },

                '& button': {
                    fontSize: 24
                }
            }
        }
    })
);

export const EditSingleSlotFullCalendar: React.FC<EditSingleSlotFullCalendarProps> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [slot, setSlot] = React.useState(props.slot);
    const [isLoading, setIsLoading] = React.useState(false);

    const staffState = useSelector((state: RootState) => state.staff);

    const isSlotEdited = React.useMemo(() => {
        return props.slot.petsCount !== slot.petsCount;
    }, [props.slot.petsCount, slot.petsCount]);

    const desktopPosition = React.useMemo(() => {
        if (!props.slotElement) {
            return { x: 0, y: 0 };
        }

        const sidebarElement = document.querySelector('[data-testid="sidebar"]');

        const rect = props.slotElement.getBoundingClientRect();

        const yPosition = rect.top + props.slotElement.offsetHeight;
        let xPosition = rect.left + props.slotElement.offsetWidth - 185;

        const maxXPosition = window.outerWidth - (sidebarElement?.scrollWidth || 0) - 170;

        if (xPosition >= maxXPosition) {
            xPosition = maxXPosition;
        }

        return {
            x: xPosition,
            y: yPosition
        };
    }, [props.slotElement]);

    const changeSlotHandler = (value: number) => {
        setSlot({ ...slot, petsCount: value });
    };

    const saveStaffSlotHandler = () => {
        const staff = staffState.staff.find(s => s.id === props.staffId);

        if (!staff) {
            return;
        }

        setIsLoading(true);
        const isCustomDaySlot = isCustomSlot(slot);

        const staffSlots: StaffSlots = {
            ...staff.slots,

            // If the slot is a custom day slot, we need to add it to the custom days array
            ...(isCustomDaySlot && {
                customDays: [...staff.slots.customDays.filter(staffSlot => staffSlot.uuid !== slot.uuid), slot]
            }),

            // If the slot is a default slot, we need to create a new custom day slot for given date/time
            ...(!isCustomDaySlot && {
                customDays: [
                    ...staff.slots.customDays,
                    {
                        on: true,
                        uuid: uuid(),
                        date: props.date,
                        time: slot.time,
                        petsCount: slot.petsCount
                    }
                ]
            })
        };

        dispatch(
            saveStaffSlotsThunk(
                {
                    ...staff,
                    slots: staffSlots
                },
                () => {
                    setIsLoading(false);
                    props.onClose();
                }
            )
        );
    };

    return (
        <>
            <div className={classes.backdrop}></div>

            <ClickAwayListener onClickAway={props.onClose}>
                <div
                    className={classes.root}
                    style={{
                        left: desktopPosition.x,
                        top: desktopPosition.y
                    }}
                >
                    <button
                        className={classes.closeButton}
                        onClick={props.onClose}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>

                    <div className={classes.chip}></div>

                    <Typography className={classes.title}>Pets</Typography>

                    <CounterField
                        minLimit={0}
                        disabled={isLoading}
                        value={slot.petsCount}
                        className={classes.counterField}
                        onChange={changeSlotHandler}
                    />

                    <Button
                        label="Save"
                        variant="green"
                        isLoading={isLoading}
                        disabled={!isSlotEdited}
                        className={classes.saveButton}
                        onClick={saveStaffSlotHandler}
                    />
                </div>
            </ClickAwayListener>
        </>
    );
};
