import { Reducer } from 'redux';
import { UpdateExpiredVaccinesState, UpdateExpiredVaccinesStatus } from './UpdateExpiredVaccinesState';
import {
    CLIENT_FETCH_START,
    CLIENT_FETCH_SUCCESS,
    CLIENT_FETCH_ERROR,
    UPDATE_VACCINES_SAVE_START,
    UPDATE_VACCINES_SAVE_SUCCESS,
    UPDATE_VACCINES_SAVE_ERROR,
    UpdateVaccinesAction
} from 'actions/updateExpiredVaccines/UpdateExpiredVaccinesActionTypes';

const initialState: UpdateExpiredVaccinesState = {
    clientExpiredVaccines: null,
    status: UpdateExpiredVaccinesStatus.Initial,
    loading: false,
    error: false
};

export const UpdateExpiredVaccinesReducer: Reducer<UpdateExpiredVaccinesState, UpdateVaccinesAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case CLIENT_FETCH_START:
            return {
                ...state,
                status: UpdateExpiredVaccinesStatus.Getting,
                loading: true
            };
        case CLIENT_FETCH_SUCCESS:
            return {
                ...state,
                clientExpiredVaccines: action.payload.clientExpiredVaccines,
                status: UpdateExpiredVaccinesStatus.GettingSuccess,
                loading: false
            };
        case CLIENT_FETCH_ERROR:
            return {
                ...state,
                clientExpiredVaccines: null,
                status: UpdateExpiredVaccinesStatus.Error,
                loading: false,
                error: true
            };
        case UPDATE_VACCINES_SAVE_START:
            return {
                ...state,
                status: UpdateExpiredVaccinesStatus.Getting,
                loading: true
            };
        case UPDATE_VACCINES_SAVE_SUCCESS:
            return {
                ...state,
                status: UpdateExpiredVaccinesStatus.SaveSuccess,
                loading: false
            };
        case UPDATE_VACCINES_SAVE_ERROR:
            return {
                ...state,
                status: UpdateExpiredVaccinesStatus.Error,
                loading: false,
                error: true
            };
        default:
            return state;
    }
};
