import { faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import clsx from 'clsx';

interface PreevNextSelectorProps {
    label: string;
    onPrev?: () => void;
    onNext?: () => void;
    onClick?: () => void;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center'
        },
        label: {
            lineHeight: 1.2,
            fontWeight: 600,
            fontFamily: 'Poppins',

            [theme.breakpoints.up('md')]: {
                fontSize: 16
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: 18
            }
        },
        iconContainer: {
            height: '100%',
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center'
        },
        icon: {
            [theme.breakpoints.down('sm')]: {
                width: '14px',
                height: '14px'
            },
            [theme.breakpoints.only('md')]: {
                width: '16px',
                height: '16px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '20px',
                height: '20px'
            },
            [theme.breakpoints.only('xl')]: {
                width: '26px',
                height: '26px'
            }
        },
        labelContainer: {
            'cursor': 'pointer',
            'padding': '4px 8px',
            'borderRadius': '50px',
            'border': '1px solid white',
            '&:hover': {
                border: '1px solid black'
            },

            [theme.breakpoints.up('sm')]: {
                paddingLeft: 15,
                paddingRight: 15
            }
        }
    })
);

export const PreevNextSelector: FunctionComponent<PreevNextSelectorProps> = props => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.container, props.className)}>
            {props.onPrev && (
                <Box
                    id="calendar_button_prev"
                    onClick={props.onPrev}
                    className={classes.iconContainer}
                >
                    <FontAwesomeIcon
                        icon={faCaretLeft}
                        className={classes.icon}
                    />
                </Box>
            )}
            <Box
                className={classes.labelContainer}
                onClick={props.onClick}
            >
                <Typography className={classes.label}>{props.label}</Typography>
            </Box>
            {props.onNext && (
                <Box
                    id="calendar_button_next"
                    onClick={props.onNext}
                    className={classes.iconContainer}
                >
                    <FontAwesomeIcon
                        icon={faCaretRight}
                        className={classes.icon}
                    />
                </Box>
            )}
        </Box>
    );
};

export default PreevNextSelector;
