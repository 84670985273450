import { InsightsReport, InsightReport } from 'model/ClientAndPetsReport';
import store from 'store';
import { ClientsInsightsReportDto } from './ClientsInsightsReportDto';

export const convertToClientsReport = (pets: ClientsInsightsReportDto): InsightsReport => {
    const timeZone = store.getState().login.auth.timeZone;

    const clientsReport: InsightsReport = {
        pets: [],
        pagination: {
            page: Number(pets.page),
            pageSize: Number(pets.size),
            totalRecords: pets.total_records,
            totalPages: pets.total_pages
        },
        page: pets.page,
        pageSize: pets.size,
        totalRecords: pets.total_records,
        totalPages: pets.total_pages
    };

    pets.pets?.forEach(pet => {
        const petConverted: InsightReport = {
            id: pet?.id,
            clientFirstName: pet.customers[0].first_name,
            clientLastName: pet.customers[0].last_name,
            petName: pet.name,
            firstVisit: pet.first_visit,
            lastVisit: pet.last_visit,
            averageFrequencyWeeks: pet.average_frequency_weeks,
            totalCompleted: pet.total_completed,
            totalCancelations: pet.total_cancelations,
            totalNoShows: pet.total_no_shows,
            lifetimeSale: pet.lifetime_sale,
            averageTicket: pet.average_ticket,
            totalTips: pet.total_tips,
            active: pet.active,
            deleted: pet.deleted,
            deceased: pet.deceased
        };

        clientsReport.pets.push(petConverted);
    });

    return clientsReport;
};
