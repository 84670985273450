import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError } from '@spike/model';
import clsx from 'clsx';
import { PetVaccine, VaccineStatus } from '@spike/medical-history-model';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { v4 as uuid } from 'uuid';
import VaccineRow from './VaccineRow';

interface VaccinesProps {
    vaccines: Array<PetVaccine>;
    errors?: Array<FieldError>;
    onChange?: (vaccines: Array<PetVaccine>) => void;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap'
        },
        vaccinesContainer: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '40px'
            },
            [theme.breakpoints.down('md')]: {
                marginTop: '0px'
            }
        },
        addVaccine: {
            cursor: 'pointer',
            color: '#92B4A7',
            fontWeight: 500,
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18)}px`,
                marginLeft: `${reduceResolution(40)}px`,
                marginTop: `${reduceResolution(8)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px',
                marginLeft: '40px',
                marginTop: '8px'
            },
            [theme.breakpoints.down('md')]: {
                'marginLeft': '0px',
                'marginTop': '0px',
                'fontSize': '14px',
                '& span': {
                    textDecoration: 'underline',
                    textUnderlineOffset: '4px'
                }
            }
        }
    })
);

export const Vaccines: FunctionComponent<VaccinesProps> = props => {
    const classes = useStyles();

    const createEmptyVaccine = () => {
        return {
            id: undefined,
            uuid: uuid(),
            expiration: undefined,
            status: VaccineStatus.ACTIVE,
            recordUrl: undefined,
            recordFileToUpload: undefined,
            recordRemove: false,
            type: undefined
        };
    };

    const changeHandler = (updatedVaccine: PetVaccine) => {
        props.onChange &&
            props.onChange(
                props.vaccines.map(vaccine =>
                    vaccine.uuid === updatedVaccine.uuid ? { ...updatedVaccine } : { ...vaccine }
                )
            );
    };

    const addHandler = () => {
        props.onChange && props.onChange([...props.vaccines, createEmptyVaccine()]);
    };

    const removeHandler = (uuid: string) => {
        props.onChange && props.onChange(props.vaccines.filter(vaccine => vaccine.uuid !== uuid));
    };

    const vaccines = (
        <Box className={classes.vaccinesContainer}>
            {props.vaccines.map(vaccine => (
                <VaccineRow
                    key={vaccine.uuid}
                    vaccine={vaccine}
                    onChange={changeHandler}
                    onRemove={() => removeHandler(vaccine.uuid)}
                    errors={props.errors}
                    updateVaccines={false}
                />
            ))}
            <Typography
                className={classes.addVaccine}
                onClick={addHandler}
            >
                + <span>Add vaccine</span>
            </Typography>
        </Box>
    );

    return <Box className={clsx(classes.container, props.className)}>{vaccines}</Box>;
};

export default Vaccines;
