import entries from 'lodash/entries';
import { UserNotification, UserNotificationSubtype, UserNotificationType } from 'model/UserNotifications';
import moment from 'moment-timezone';
import { UserReceiptDto } from './userNotificationsDtos';

export const convertToUserNotification = (userReceiptDto: UserReceiptDto, timeZone: string): UserNotification => {
    const [typeDto, subtypeDto] = userReceiptDto.notification_type_id.split('_');

    let type = UserNotificationType.NONE;
    let subtype = undefined;

    if (userReceiptDto.notification_type_id.includes('booking')) {
        type = entries(UserNotificationType).find(entry => entry[0].toUpperCase() === typeDto?.toUpperCase())![1];

        const subtypeEnum = entries(UserNotificationSubtype).find(
            entry => entry[0].toUpperCase() === subtypeDto?.toUpperCase()
        );

        subtype = subtypeEnum ? subtypeEnum[1] : undefined;
    }

    if (userReceiptDto.notification_type_id.includes('intake_form')) {
        type = UserNotificationType.INTAKE_FORM;
    }

    if (userReceiptDto.notification_type_id === 'booking_request') {
        type = UserNotificationType.BOOKING_REQUEST;
    }

    if (userReceiptDto.notification_type_id === 'vaccine_expired') {
        type = UserNotificationType.VACCINE_EXPIRED;
    }

    return {
        id: userReceiptDto.notification_id,
        uuid: userReceiptDto.uuid,
        marketplaceId: userReceiptDto.marketplace_id,
        subject: userReceiptDto.notification_subject,
        body: userReceiptDto.notification_body,
        type: type,
        subtype: subtype,
        readAt: userReceiptDto.read_at ? moment(userReceiptDto.read_at).tz(timeZone) : undefined,
        sentAt: userReceiptDto.sent_at
            ? moment(userReceiptDto.sent_at).tz(timeZone)
            : moment(userReceiptDto.created_at).tz(timeZone),
        updatedAt: moment(userReceiptDto.updated_at).tz(timeZone),
        createdAt: moment(userReceiptDto.created_at).tz(timeZone),
        openedAt: userReceiptDto.received_at ? moment(userReceiptDto.received_at).tz(timeZone) : undefined,
        appointmentId:
            userReceiptDto.target_type?.toLowerCase() === 'appointment' ? userReceiptDto.target_id : undefined,
        bookingId: userReceiptDto.target_type?.toLowerCase() === 'booking' ? userReceiptDto.target_id : undefined,
        clientId: userReceiptDto.target_type?.toLowerCase() === 'customer' ? userReceiptDto.target_id : undefined,
        bookingStatus:
            userReceiptDto.target_type?.toLowerCase() === 'booking' ? userReceiptDto.target_status : undefined,
        receiptId: userReceiptDto.id,
        petId: userReceiptDto.target_type?.toLowerCase() === 'pet' ? userReceiptDto.target_id : undefined
    };
};
