import React from 'react';
import { EventContentArg } from '@fullcalendar/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { faPaw } from '@fortawesome/pro-light-svg-icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Appointment from '@spike/appointment-model';

export type EventSlotFullCalendarProps = EventContentArg & {
    appointments: Array<Appointment>;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 12,
            color: '#000',
            fontWeight: 500,
            borderRadius: 6,
            margin: '2px 0px',
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            justifyContent: 'space-between',
            backgroundColor: '#EFEFEF',

            [theme.breakpoints.up('md')]: {
                fontSize: 14,
                margin: '3px 0px',
                padding: '6px 5px'
            }
        },
        petsCountBadge: {
            'gap': 2,
            'fontSize': 12,
            'lineHeight': 1,
            'fontWeight': 600,
            'color': '#ffffff',
            'borderRadius': 3.5,
            'padding': '4.5px 5px',
            'display': 'inline-flex',
            'alignItems': 'center',
            'backgroundColor': '#000000',

            [theme.breakpoints.up('md')]: {
                fontSize: 14
            },

            '& svg': {
                marginRight: 4
            }
        }
    })
);

export const EventSlotFullCalendar: React.FC<EventSlotFullCalendarProps> = props => {
    const classes = useStyles();

    const appointmentsCount = React.useMemo(() => {
        const staffAppointments = props.appointments
            // filter by staff id
            .filter(appointment =>
                appointment.services.some(service => service.staff?.id === props.event.extendedProps.staffId)
            )
            // filter by date and time
            .filter(appointment => appointment.duration.from.isSame(props.event.extendedProps.from, 'minute'));

        return staffAppointments.length;
    }, [props.appointments, props.event.extendedProps.from, props.event.extendedProps.staffId]);

    return (
        <div className={classes.root}>
            <span>
                <FontAwesomeIcon icon={faClock} /> {props.event.extendedProps.from.format('h:mm A')}
            </span>

            <span className={classes.petsCountBadge}>
                <FontAwesomeIcon icon={faPaw} />
                {appointmentsCount}
                {' / '}
                {props.event.extendedProps.slot.petsCount}
            </span>
        </div>
    );
};
