import Pet from '@spike/pet-model';
import { PetSaveDto } from './UpdateExpiredVaccinesDto';
import { PetVaccineDto } from '@spike/pets-action';
import { PetVaccine } from '@spike/medical-history-model';

export const convertToSavePetDto = (pet: Pet, newVaccines: Array<PetVaccine>, marketplaceId: number): PetSaveDto => {
    return {
        id: pet.id,
        uuid: pet.uuid,
        marketplace_id: marketplaceId,
        pet_vaccine_expirations_attributes: newVaccines.map(vaccine =>
            convertPetVaccineToDto(marketplaceId, vaccine, pet.id)
        )
    };
};

export const convertPetVaccineToDto = (marketplaceId: number, vaccine: PetVaccine, petId?: number): PetVaccineDto => {
    return {
        marketplace_id: marketplaceId,
        pet_id: petId,
        vaccine_id: vaccine.type?.id,
        expiration_date: vaccine.expiration?.format('YYYY-MM-DD'),
        //@ts-ignore
        file: vaccine.record?.remove ? new Blob() : vaccine.record?.upload ? vaccine.record.file : undefined,
        remove_file: vaccine.record?.remove
    };
};
