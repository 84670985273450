import SignatureCanvas from 'react-signature-canvas';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';

interface Props {
    height?: number;
    onRef?: React.LegacyRef<SignatureCanvas> | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'padding': '30px 45px',
            'backgroundColor': '#fff',
            'border': '1px dashed #000000',

            '& canvas': {
                width: '100%',
                borderBottom: 'solid 1px #D4D4D4'
            }
        }
    })
);

const SignPad = ({ height = 170, onRef }: Props) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <SignatureCanvas
                clearOnResize={false}
                penColor="black"
                canvasProps={{ height }}
                ref={onRef}
            />
        </Box>
    );
};

export default SignPad;
