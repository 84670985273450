import React, { useEffect, useState } from 'react';
import { Box, Drawer, Typography, createStyles, makeStyles } from '@material-ui/core';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdyenPayment, { CompletedInfo } from 'components/Payments/Adyen/AdyenPayment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubscriptionsThunk } from 'actions/subscriptions/SubscriptionsActions';
import { RootState } from 'store';
import { Subscription } from 'model/Subscriptions';
import { clsx } from 'clsx';

interface Props {
    open: boolean;
    onClose: () => void;
    onComplete: () => void;
    onError: (error: string) => void;
}

const useStyles = makeStyles(theme =>
    createStyles({
        container: {
            'display': 'flex',
            'height': '100%',
            'flexDirection': 'column',
            'padding': '0px 30px 30px 35px',
            '&.MuiDrawer-root.MuiDrawer-modal': {
                zIndex: '1001 !important'
            },
            '& .MuiDrawer-paperAnchorRight': {
                width: '100%',
                [theme.breakpoints.up('md')]: {
                    width: '590px !important'
                }
            }
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '30px 0px 25px 0px'
        },
        body: {},
        backdrop: {
            backgroundColor: 'transparent'
        },
        title: {
            fontSize: 22,
            fontWeight: 600,
            [theme.breakpoints.down('sm')]: {
                fontSize: 20
            }
        },
        icon: {
            fontSize: 24,
            fontWeight: 500,
            [theme.breakpoints.down('sm')]: {
                fontSize: 20
            }
        },
        messageContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        errorIcon: {
            color: '#C14A4A',
            fontSize: 50,
            margin: '50px 0px 15px 0px'
        },
        error: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: '#C14A4A',
            width: '100%',
            textAlign: 'center',
            fontSize: 18
        }
    })
);

const CreditCardUpdate: React.FunctionComponent<Props> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [error, setError] = useState<string | undefined>(undefined);

    const activeSubscription = useSelector<RootState, Array<Subscription>>(
        state => state.subscriptions.subscriptions
    ).find(subscription => subscription.active);

    useEffect(() => {
        dispatch(fetchSubscriptionsThunk());
    }, []);

    const closeHandler = () => {
        setError(undefined);
        props.onClose();
    };

    const completeHandler = (info: CompletedInfo) => {
        if (info.success) {
            props.onComplete && props.onComplete();
        } else if (info.resultCode) {
            props.onError && props.onError(info.resultCode);
            closeHandler();
        }
    };

    return (
        <Drawer
            open={props.open}
            onClose={closeHandler}
            className={classes.container}
            anchor="right"
            classes={{
                root: classes.container,
                paper: classes.container
            }}
            BackdropProps={{
                classes: {
                    root: classes.backdrop
                }
            }}
        >
            <Box className={classes.header}>
                <Typography className={classes.title}>Edit Credit Card</Typography>
                <FontAwesomeIcon
                    className={classes.icon}
                    icon={faXmark}
                    onClick={closeHandler}
                />
            </Box>
            {error && (
                <Box className={classes.messageContainer}>
                    <FontAwesomeIcon
                        icon={faCircleXmark}
                        className={clsx(classes.icon, classes.errorIcon)}
                    />
                    <Typography className={classes.error}>{error}</Typography>
                </Box>
            )}

            {!error && (
                <Box className={classes.body}>
                    <AdyenPayment
                        subscriptionId={activeSubscription?.id}
                        onError={setError}
                        actionType="changePaymentMethod"
                        onComplete={completeHandler}
                        hideRemovePaymentMethodButton
                        hideStoredPaymentMethods
                    />
                </Box>
            )}
        </Drawer>
    );
};

export default CreditCardUpdate;
