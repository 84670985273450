import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';
import ChipMUI from '@material-ui/core/Chip';
import PetImage from 'components/PetInformation/PetImage';
import Pet from '@spike/pet-model';
import PetDescription from 'components/UI/PetCard/PetDescription';

interface Props {
    pet: Pet;
    countVaccinesExpired: number;
    onUpdatePetVaccines: (petSelected: Pet) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            border: '1px solid #D3D3D3',
            borderRadius: 10,
            alignItems: 'center',
            height: 'auto',
            paddingLeft: 12,
            paddingRight: 12,
            paddingBottom: 15,
            paddingTop: 15,
            cursor: 'pointer',
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                height: 'auto',
                alignItems: 'flex-start'
            }
        },
        content: {
            display: 'flex'
        },
        title: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '27px'
        },
        icon: {
            fontWeight: 400,
            fontSize: 20,
            lineHeight: '27px',
            position: 'absolute',
            right: 12,
            [theme.breakpoints.down('sm')]: {
                top: '50%',
                transform: 'translateY(-50%)'
            }
        },
        spacing: {
            marginRight: 10,
            marginTop: 2
        },
        iconSize: {
            fontSize: '16px !important'
        },
        image: {
            width: 64,
            height: 64,
            marginRight: 18,
            [theme.breakpoints.up('md')]: {
                marginRight: 20
            }
        },
        petDescriptionContainer: {
            display: 'flex',
            width: '80%'
        },
        notifications: {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '25px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                justifyContent: 'flex-start',
                paddingLeft: '75px'
            }
        },
        chip: {
            'borderColor': 'transparent',
            'cursor': 'pointer',
            'color': '#EF4F57',
            'backgroundColor': '#FBB9BC4D',
            '&:hover': {
                backgroundColor: '#FBB9BC4D !important'
            },
            [theme.breakpoints.down('sm')]: {
                //width: '50px',
                height: '22px',
                fontSize: '12px'
            },
            [theme.breakpoints.only('md')]: {
                //width: '54px',
                height: '21px',
                fontSize: '12px'
            },
            [theme.breakpoints.only('lg')]: {
                //width: '52px',
                height: '23px',
                fontSize: '12px'
            },
            [theme.breakpoints.only('xl')]: {
                //width: '58px',
                height: '26px',
                fontSize: '14px'
            }
        }
    })
);

export const PetVaccinesCard: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const labelExpirationVaccines =
        props.countVaccinesExpired === 1
            ? `${props.countVaccinesExpired} Vaccine Expired`
            : `${props.countVaccinesExpired} Vaccines Expired`;

    return (
        <Box
            className={classes.container}
            onClick={() => {
                props.onUpdatePetVaccines(props.pet);
            }}
        >
            <Box className={classes.content}>
                <Box className={classes.image}>
                    <PetImage
                        petImage={props.pet?.image}
                        petType={props.pet?.type?.id}
                    />
                </Box>
                <Box className={classes.petDescriptionContainer}>
                    <PetDescription pet={props.pet} />
                </Box>
            </Box>
            {props.countVaccinesExpired > 0 && (
                <Box className={classes.notifications}>
                    <ChipMUI
                        label={labelExpirationVaccines}
                        className={clsx(classes.chip)}
                        clickable={false}
                    />
                </Box>
            )}
            <FontAwesomeIcon
                icon={faChevronRight}
                className={clsx(classes.icon, classes.iconSize)}
            />
        </Box>
    );
};

export default PetVaccinesCard;
