import { Box, ClickAwayListener } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import useNonInitialEffect from '@versiondos/hooks';
import { fetchStaffThunk } from 'actions/staff/StaffActions';
import { CalculatingSpinner } from 'components/UI';
import UIButton from 'components/UI/Button';
import Staff from 'model/Staff';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StaffStatus } from 'reducers/staff/StaffState';
import { RootState } from 'store';
import Button from './Button';
import List from './List';
import ListItem from './ListItem';

interface StaffSelectorProps {
    selectedIds: Array<number> | undefined;
    onSelect?: (selectedStaffIds: Array<number> | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            position: 'relative',
            minWidth: 100,

            [theme.breakpoints.up('md')]: {
                minWidth: '122px'
            }
        },
        listContainer: {
            position: 'absolute',
            left: 0,
            zIndex: 10,
            backgroundColor: 'white',
            border: '1px solid black',
            borderRadius: 12,
            [theme.breakpoints.down('sm')]: {
                top: 30
            },
            [theme.breakpoints.up('sm')]: {
                top: 50
            }
        },
        listHeader: {
            padding: '10px 15px',
            borderBottom: '1px solid #EEEEEE'
        },
        list: {
            paddingTop: '10px'
        },
        listFooter: {
            padding: '10px 15px'
        },
        applyButton: {
            width: '100%',
            height: '36px'
        },
        spinnerContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px 0px'
        }
    })
);

export const StaffSelector: FunctionComponent<StaffSelectorProps> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const nameLenght = 10;

    const staff = useSelector<RootState, Array<Staff>>(state => state.staff.staff);
    const staffStatus = useSelector<RootState, StaffStatus>(state => state.staff.status);

    const options = useMemo(
        () =>
            staff
                .filter(staff => staff.active)
                .map(member => ({
                    id: member.id ?? 0,
                    name: `${member.person.firstName} ${member.person.lastName}`
                })),
        [staff]
    );

    const [loading, setLoading] = useState(true);
    const [showList, setShowList] = useState(false);
    const [selectedIds, setSelectedIds] = useState<Array<number>>(
        props.selectedIds ?? staff.map(member => member.id ?? 0)
    );

    const buttonLabel =
        props.selectedIds === undefined
            ? 'All Staff'
            : props.selectedIds.length === 1
            ? `${(options.find(option => option.id === selectedIds[0])?.name ?? '')
                  .substring(0, nameLenght)
                  .trimRight()}${
                  (options.find(option => option.id === selectedIds[0])?.name ?? '').length > nameLenght ? '...' : ''
              }`
            : `Staff (${props.selectedIds.length})`;

    useEffect(() => {
        props.selectedIds === undefined ? dispatch(fetchStaffThunk()) : setLoading(false);
    }, []);

    useNonInitialEffect(() => {
        switch (staffStatus) {
            case StaffStatus.FetchSuccess:
                setSelectedIds(staff.map(member => member.id ?? 0));
                setLoading(false);
                break;
            case StaffStatus.Error:
                setLoading(false);
        }
    }, [staffStatus]);

    const allClickHandler = () => {
        if (selectedIds.length === options.length) {
            setSelectedIds([]);
        } else {
            setSelectedIds(options.map(option => option.id));
        }
    };

    const applyHandler = () => {
        props.onSelect && props.onSelect(selectedIds.length === options.length ? undefined : selectedIds);
        setShowList(false);
    };

    const toggleShowList = () => {
        showList &&
            setSelectedIds(
                props.selectedIds === undefined ? options.map(option => option.id) : props.selectedIds ?? []
            );
        setShowList(prev => !prev);
    };

    return (
        <Box className={classes.container}>
            <Button
                label={buttonLabel}
                onClick={toggleShowList}
            />
            {showList && (
                <ClickAwayListener onClickAway={toggleShowList}>
                    <Box className={classes.listContainer}>
                        <Box className={classes.listHeader}>
                            <ListItem
                                id={0}
                                label="All Staff"
                                onClick={allClickHandler}
                                selected={selectedIds.length === options.length}
                            />
                        </Box>
                        {loading ? (
                            <Box className={classes.spinnerContainer}>
                                <CalculatingSpinner />
                            </Box>
                        ) : (
                            <List
                                options={options}
                                selectedIds={selectedIds}
                                onChangeSelection={setSelectedIds}
                                className={classes.list}
                            />
                        )}
                        <Box className={classes.listFooter}>
                            <UIButton
                                label="Apply"
                                onClick={applyHandler}
                                color="green"
                                className={classes.applyButton}
                            />
                        </Box>
                    </Box>
                </ClickAwayListener>
            )}
        </Box>
    );
};

export default StaffSelector;
