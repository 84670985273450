import React, { FunctionComponent } from 'react';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

export interface SectionTitleProps extends PropsWithChildren {
    className?: string;
    subtitle?: string;
    containerClassName?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginBottom: 25,

            [theme.breakpoints.up('md')]: {
                marginBottom: 30
            }
        },
        title: {
            'fontSize': 20,
            'fontWeight': 600,
            'display': 'flex',
            'alignItems': 'center',
            '& svg': {
                fontSize: 20,
                marginRight: 8,
                position: 'relative'
            }
        },
        subtitle: {
            width: '100%',
            fontSize: '16px',
            lineHeight: '42px'
        }
    })
);

export const BusinessSettingsTitle: FunctionComponent<SectionTitleProps> = props => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.container, props.containerClassName)}>
            <Typography
                className={clsx(classes.title, props.className)}
                noWrap={true}
            >
                {props.children}
            </Typography>
            {props.subtitle && <Typography className={classes.subtitle}>{props.subtitle}</Typography>}
        </Box>
    );
};

export default BusinessSettingsTitle;
