import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core';
import 'react-calendar/dist/Calendar.css';
import Pet from '@spike/pet-model';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from 'constants/index';
import SelectPets from './SelectPets';
import PetInfo from './PetInfo';
import PetCard from 'components/UI/PetCard';

interface Props {
    pet?: Pet;
    pets: Array<Pet>;
    petId?: number;
    hideSelect?: boolean;
    parentID: number | undefined;
    multiplePets?: boolean;
    onPetSelect: (value: Pet) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxFlexRow: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        petInfoContainer: {
            borderBottom: (props: Props) => (props.multiplePets ? 'none' : '1px solid #D4D4D4'),
            marginTop: 32,
            paddingBottom: 32,
            [theme.breakpoints.up('md')]: {
                marginTop: 26,
                paddingBottom: 18
            }
        },
        petCardContainer: {
            borderBottom: (props: Props) => (props.multiplePets ? 'none' : '1px solid #D4D4D4'),
            marginTop: 10,
            paddingBottom: 10,
            [theme.breakpoints.up('md')]: {
                marginTop: 8,
                paddingBottom: 8
            }
        },
        selectContainer: {
            width: '100%'
        }
    })
);

export const PetsComponent: FunctionComponent<Props> = props => {
    const classes = useStyles(props);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.petId]);

    const handlerPet = (value: string) => {
        amplitude.track(AMPLITUDE.CHECK_PETS);
        const Pet = pets.filter(pet => pet.id === Number(value));
        props.onPetSelect(Pet[0]);
    };

    const pets = useMemo(() => {
        return props.pets.length ? props.pets.filter(pet => pet.active && !pet.deceased) : [];
    }, [props.pets]);

    useEffect(() => {
        if (pets.length === 1) {
            handlerPet(pets[0].id!.toString()!);
        }

        if (props.petId !== undefined) {
            handlerPet(props.petId.toString());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.parentID, pets]);

    const loadingView = (
        <CircularProgress
            size="1.5rem"
            style={{ color: '#AAA' }}
        />
    );

    const petSelectView = (
        <SelectPets
            id="booking_pet_search"
            selectedOption={props.petId?.toString()}
            placeholder="Select a Pet"
            options={pets}
            onSelect={props.onPetSelect}
            className={classes.selectContainer}
        />
    );

    const petInfoView = (
        <Box className={classes.petInfoContainer}>
            <PetInfo pet={pets.find(opt => opt.id === props.petId)} />
        </Box>
    );

    const petCardView = (
        <Box className={classes.petCardContainer}>
            <PetCard pet={pets.find(opt => opt.id === props.petId)} />
        </Box>
    );

    return (
        <Box className={classes.boxFlexRow}>
            {loading && loadingView}
            <Box className={classes.boxFlexRow}>
                {!loading && !props.hideSelect && petSelectView}
                {!loading && props.petId && !props.multiplePets && petInfoView}
                {!loading && props.petId && props.multiplePets && petCardView}
            </Box>
        </Box>
    );
};

export default PetsComponent;
