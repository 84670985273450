// src/actions/updateVaccines/UpdateVaccinesActionTypes.ts

import { Action } from 'redux';
import { ClientExpiredVaccinesDto } from './UpdateExpiredVaccinesDto';

//Types
export const CLIENT_FETCH_START = 'CLIENT_FETCH_START';
export const CLIENT_FETCH_ERROR = 'CLIENT_FETCH_ERROR';
export const CLIENT_FETCH_SUCCESS = 'CLIENT_FETCH_SUCCESS';

export const UPDATE_VACCINES_SAVE_START = 'UPDATE_VACCINES_SAVE_TIP_START';
export const UPDATE_VACCINES_SAVE_SUCCESS = 'UPDATE_VACCINES_SAVE_TIP_SUCCESS';
export const UPDATE_VACCINES_SAVE_ERROR = 'UPDATE_VACCINES_SAVE_TIP_ERROR';

// Actions
type ClientFetchStartAction = Action<typeof CLIENT_FETCH_START>;

interface ClientFetchSuccessAction extends Action<typeof CLIENT_FETCH_SUCCESS> {
    payload: {
        loading: boolean;
        clientExpiredVaccines: ClientExpiredVaccinesDto;
    };
}

interface ClientFetchErrorAction extends Action<typeof CLIENT_FETCH_ERROR> {
    payload: {
        loading: boolean;
        fetchError: string;
    };
}

type UpdateVaccinesSaveStartAction = Action<typeof UPDATE_VACCINES_SAVE_START>;

interface UpdateVaccinesSaveSuccessAction extends Action<typeof UPDATE_VACCINES_SAVE_SUCCESS> {
    payload: {
        loading: boolean;
    };
}

interface UpdateVaccinesSaveErrorAction extends Action<typeof UPDATE_VACCINES_SAVE_ERROR> {
    payload: {
        loading: boolean;
        saveError: string;
    };
}

export type UpdateVaccinesAction =
    | ClientFetchStartAction
    | ClientFetchSuccessAction
    | ClientFetchErrorAction
    | UpdateVaccinesSaveStartAction
    | UpdateVaccinesSaveSuccessAction
    | UpdateVaccinesSaveErrorAction;
