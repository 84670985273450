// src/components/PayByLink/styles/PayByLinkStyles.ts
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { max } from 'lodash';
import { reduceResolution, wbp } from 'Theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            transition: 'all 0.33s linear',
            paddingBottom: 50,
            width: '100%',
            height: '100%',
            display: 'block',
            [theme.breakpoints.down('sm')]: {
                paddingBottom: 15
            }
        },
        body: {
            'paddingTop': 40,
            'flex': 'auto',
            'display': 'flex',
            'flexDirection': 'column',
            'alignItems': 'center',
            'width': '100%',
            'maxWidth': 600,
            'paddingLeft': 15,
            'paddingRight': 15,
            '& hr': {
                border: 'none',
                margin: '35px 0px',
                borderTop: 'solid 1px #D4D4D4'
            },
            [theme.breakpoints.down('sm')]: {
                paddingTop: 10
            }
        },
        spinnerStyle: {
            height: '100%'
        },
        wrapper: {
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center'
        },
        logo: {
            'width': 120,
            'borderRadius': 8,
            'overflow': 'hidden',
            'display': 'flex',
            'justifyContent': 'center',
            'alignItems': 'center',
            'marginRight': theme.spacing(2),
            '& img': {
                maxWidth: '100%',
                display: 'block'
            }
        },
        storeInfo: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            borderBottom: '1px solid #D4D4D4',
            paddingBottom: 20,
            marginBottom: 30,
            [theme.breakpoints.down('md')]: {
                display: 'block',
                paddingBottom: 30
            }
        },
        storeInfoIcon: {
            minWidth: 15
        },
        storeDetails: {
            display: 'flex',
            flexDirection: 'column'
        },
        storeName: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: 1.2
        },
        storeContact: {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(1),
            fontSize: 14,
            color: '#000'
        },
        storeAddress: {
            display: 'flex',
            alignItems: 'center',
            marginTop: 5,
            fontSize: 14,
            lineHeight: 1.2,
            color: '#000'
        },
        boxDefault: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            borderRadius: 12,
            marginBottom: theme.spacing(2)
        },
        petVaccinesDetails: {
            display: 'flex'
        },
        petVaccinesDetailsInfo: {
            paddingLeft: 15,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        spinnerContent: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 250
        },
        footer: {
            marginTop: 70,
            textAlign: 'center',
            paddingBottom: 30,
            [theme.breakpoints.down('sm')]: {
                marginTop: 40
            }
        },
        footerText: {
            textTransform: 'uppercase',
            fontSize: 10,
            letterSpacing: '0.96px',
            color: '#000',
            opacity: 0.5,
            marginBottom: 5,
            paddingLeft: 2
        },
        titleContainer: {
            width: '100%',
            backgroundColor: '#fff',
            borderBottom: '1px solid #D4D4D4',
            textAlign: 'center',
            padding: theme.spacing(2, 0),
            position: 'relative',
            top: 0,
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                borderBottom: 'none'
            }
        },
        petVaccinesTitle: {
            fontWeight: 600,
            fontSize: 20,
            marginBottom: 5
        },
        subtitle: {
            fontSize: 16
        },
        cuddlesLogo: {
            display: 'inline-block'
        },
        error: {
            color: '#FF0000'
        },
        button: {
            position: 'relative',
            width: '100%',
            minHeight: 50
        },
        backButton: {
            'display': 'flex',
            'alignItems': 'center',
            'position': 'absolute',
            'top': 'auto',
            'bottom': 'auto',
            'left': 25,
            'cursor': 'pointer',
            '& svg': {
                marginRight: 8
            },
            '& p': {
                fontWeight: 500
            }
        },
        backButtonText: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'inline-block'
            }
        },
        alertBox: {
            'marginTop': 25,
            'marginBottom': 25,
            '& p': {
                'fontWeight': 500,
                'fontSize': 14,
                '& strong': {
                    fontWeight: 600
                }
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: 10
            }
        },
        notFoundContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'calc(100vh - 200px)',
            width: '100%',
            flexDirection: 'column'
        },
        blockedCursor: {
            cursor: 'not-allowed',
            pointerEvents: 'none'
        },
        backImage: {
            width: 26
        },
        icon: {
            cursor: 'pointer',
            [theme.breakpoints.down(wbp)]: {
                marginRight: `${reduceResolution(10)}px`,
                width: `${reduceResolution(16)}px !important`,
                height: `${reduceResolution(16)}px !important`
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '10px',
                width: '16px !important',
                height: '16px !important'
            }
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            left: 0,
            paddingLeft: '20px'
        },
        titleBack: {
            fontFamilty: 'Poppins',
            fontWeight: 600,
            color: 'black',
            marginLeft: '8px',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px'
            }
        },
        title: {
            fontWeight: 500,
            fontSize: 21,
            textAlign: 'center',
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px'
            }
        }
    })
);

export default useStyles;
