import { faSyringe } from '@fortawesome/pro-light-svg-icons';
import { Option } from '@spike/model';

import { FunctionComponent } from 'react';
import EditSetting from './EditSetting';

export interface Props {
    onEdit?: (emailChecked: boolean, smsChecked: boolean, noticeSelected: Option<string>, optionId: string) => void;
    loading: boolean;
}

export const EditExpirationVaccine: FunctionComponent<Props> = props => {
    const key = 'client_expiration_vaccine';

    const noticeOptions = [
        { id: '2', name: '2 hrs before' },
        { id: '4', name: '4 hrs before' },
        { id: '8', name: '8 hrs before' },
        { id: '12', name: '12 hrs before' },
        { id: '24', name: '1 day before' },
        { id: '48', name: '2 days before' },
        { id: '72', name: '3 days before' }
    ];

    const handleEdit = (emailChecked: boolean, smsChecked: boolean, noticeSelected: Option<string>) => {
        props.onEdit && props.onEdit(emailChecked, smsChecked, noticeSelected, key);
    };

    const subtitle = <>Notifies clients when a pet&apos;s vaccines are due or have expired.</>;

    return (
        <EditSetting
            optionId={key}
            noticeOptions={noticeOptions}
            headerIcon={faSyringe}
            title={'Expiration Vaccine'}
            subtitle={subtitle}
            loading={props.loading}
            defaultOption={noticeOptions[4]}
            onEdit={handleEdit}
            noShowOption={true}
        />
    );
};

export default EditExpirationVaccine;
