import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import store from 'store';
import ApiClient, { createTokenConfig, isCancelled } from 'api/ApiClient';
import { NotificationsAction, showError } from '@spike/notifications-action';
import {
    CLIENT_INSIGHT_REPORT_GET_START,
    CLIENT_INSIGHT_REPORT_GET_SUCCESS,
    CLIENT_INSIGHT_REPORT_GET_ERROR,
    ClientInsightsReportAction
} from './ClientsInsightsReportActionsTypes';
import { ClientsInsightsReportDto } from './ClientsInsightsReportDto';
import { convertToClientsReport } from './clientInsightsReportConverter';

export const getClientInsightsReport = (
    page?: string | number,
    size?: string | number,
    start?: string,
    end?: string
): ThunkAction<void, null, null, ClientInsightsReportAction | NotificationsAction> => {
    return async dispatch => {
        dispatch(getClientInsightsReportStart());

        const { marketplaceId } = store.getState().login.auth;
        let url = `/customer_insights_report?marketplace_id=${marketplaceId}`;

        if (page) url += `&page=${page}`;
        if (size) url += `&size=${size}`;
        if (start) url += `&start_date=${start}`;
        if (end) url += `&end_date=${end}`;

        try {
            const response: AxiosResponse<ClientsInsightsReportDto> = await ApiClient.get(
                url,
                createTokenConfig(store.getState().login.auth.token!)
            );
            dispatch(getClientInsightsReportSuccess(response.data));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error get clients and pets - client insights report.'));
            }
        }
    };
};

const getClientInsightsReportStart = (): ClientInsightsReportAction => ({
    type: CLIENT_INSIGHT_REPORT_GET_START
});

const getClientInsightsReportSuccess = (clients: ClientsInsightsReportDto): ClientInsightsReportAction => {
    const clientsReportConverted = convertToClientsReport(clients);
    return {
        type: CLIENT_INSIGHT_REPORT_GET_SUCCESS,
        payload: {
            pets: clientsReportConverted.pets,
            page: clientsReportConverted.page,
            size: clientsReportConverted.pageSize,
            totalPages: clientsReportConverted.totalPages,
            totalRecords: clientsReportConverted.totalRecords
        }
    };
};

const error = (): ClientInsightsReportAction => ({ type: CLIENT_INSIGHT_REPORT_GET_ERROR });
